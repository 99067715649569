<template>

    <v-hover v-slot="{ props }" @update:modelValue="setHover" close-delay="200" style="position: relative;">
        <main v-bind="props" style="position: relative;" class="tooltip">
            <slot>
            </slot>
            <div class="tooltipContent" :style="getPostion">
                <v-row justify="center" align="center">
                    <v-col cols="auto" class="tooltipText">
                        <v-icon style="color:var(--C1); background-color: white;border-radius: 100%;"
                            class="mr-1">mdi-information-variant</v-icon>
                        {{ information }}
                    </v-col>
                </v-row>
            </div>
        </main>
    </v-hover>
</template>

<script>
export default {
    props: {
        information: {},
        position: {},
    },
    data() {
        return {
            isHoveringValue: false
        }
    },
    computed: {
        getPostion() {
            let options = {
                'right': 'left:0px',
                'left': 'right:0px',
                'center': 'right:0px;left:0px',
            }

            if (this.position)
                return options[this.position]

            return options['center']
        },
    },
    methods: {
        setHover(val) {
            this.isHoveringValue = val
        }
    }
}
</script>

<style>
.tooltipText {
    color: white;
    border-radius: 6px;
    background-color: rgba(0, 45, 89, 0.7);
    font-weight: bold;
    font-size: 14px;
    /* width:100%; */
}

.tooltipContent {
    visibility: hidden;
    text-align: center;
    position: absolute;
    z-index: 1;
    opacity: 1;
    transition: opacity 0.3s;
    bottom: 125%;
}

.tooltip:hover .tooltipContent {
    visibility: visible;
    opacity: 1;
}
</style>
