<template>
    <v-col v-if="false" :style="{ 'background-color': isMobile ? 'var(--C4)' : '' }">
        <!-- <v-btn @click="closeConfiguration" style="position: absolute; right: 5px; top: 5px; z-index: 2;" size="30"
            color="red" variant="flat" icon="mdi-close"></v-btn> -->
        <v-btn icon="mdi-close-circle-outline" class="button" @click="closeConfiguration()" density="compact"
            style="border-radius: 100% !important;position: absolute; right: 5px; top: 5px; z-index: 2;">
        </v-btn>
        <v-row>
            <v-col v-if="isMobile == false" cols="4" class="" style="">
                <v-card>
                    <h1 class="py-3" align="center">Votre Jauris</h1>
                    <v-col v-for="(elem, i) in settings" :key="i" class="">
                        <v-card class="" style="background-color: white;color:var(--C1)" variant="flat" width="100%"
                            :style="{ 'border-radius': '5px' }" @click="selectSetting(elem)">
                            <v-col class="py-1"
                                :style="selectedSetting && selectedSetting.label == elem.label ? 'background:var(--BG);color:white' : ''">
                                <h3>
                                    <v-icon class="pr-4" size="50"> {{ elem.icon }} </v-icon>
                                    {{ elem.label }}
                                </h3>
                            </v-col>
                        </v-card>
                    </v-col>
                </v-card>
            </v-col>
            <v-col v-else cols="12" class="pa-0">
                <h1 class="bg py-4" align="center" style="color:white">Configuration</h1>
                <div class="pa-3 bg mb-4">
                    <v-select variant="solo" hide-details :items="settings" v-model="selectedSetting" returnObject
                        item-title="label"></v-select>
                </div>
            </v-col>
            <v-col :style="{ height: isMobile ? 'auto' : '700px' }" style="background-color: var(--C4); overflow: auto;"
                v-if="selectedSetting && selectedSetting.cmp">
                <component :closeModal="closeModal" :is="selectedSetting.cmp" :getLinksFormInformation="getLinksFormInformation"
                    :userInformation="userInformation" :updateUserData="updateUserData"
                    :groupInformation="groupInformation" :blogListe="blogListe" :sendNewConfig="sendNewConfig">
                </component>
            </v-col>
        </v-row>
    </v-col>
    <v-col v-else>
        <configurationCms :closeModal="closeModal" :getLinksFormInformation="getLinksFormInformation"
            :userInformation="userInformation" :updateUserData="updateUserData" :groupInformation="groupInformation"
            :blogListe="blogListe" :sendNewConfig="sendNewConfig">
        </configurationCms>
    </v-col>
</template>

<script>

import configurationBlog from "@/components/configurations/configuration-blog.vue"
import configurationTemplates from "@/components/configurations/configuration-templates.vue"
import configurationLink from "@/components/configurations/configuration-link.vue"
import configurationCms from "@/components/configurations/configuration-cms.vue"
import configurationMedia from "@/components/configurations/configuration-media.vue"

import engine from "@/core/index"

export default {
    data() {
        return {
            isSettingsModalOpen: false,
            selectedSetting: undefined,
        }

    },
    props: {
        closeModal: { type: Function },
        getLinksFormInformation: {},
        getUserData: { type: Function },
        closeConfiguration: { type: Function },
        userInformation: {},
        groupInformation: {},
        blogListe: {}
    },
    mounted() {
        this.selectedSetting = this.settings[0]
    },
    computed: {
        settings() {
            let settings = []

            if (this.userInformation.admin_lvl >= 0) {
                settings.push({ label: 'Thématique de site', cmp: 'configurationBlog', icon: 'mdi-post-outline' })
                if (this.getLinksFormInformation.value.length > 0)
                    settings.push({ label: 'Gestion des liens', cmp: 'configurationLink', icon: 'mdi-download-network-outline' })
            }
            if (this.userInformation.admin_lvl >= 1) {
                settings.push({ label: 'Configuration média', cmp: 'configurationMedia', icon: 'mdi-folder-multiple-image' })
                settings.push({ label: 'Template par défault', cmp: 'configurationTemplates', icon: 'mdi-text-box-outline' })
                settings.push({ label: 'Synchronisation de blog avec jauris', cmp: 'configurationCms', icon: 'mdi-book-cog-outline' })
            }

            return settings
            // { label: 'Configuration du blog', cmp: 'configurationBlog', icon: 'mdi-post-outline' },
            // { label: 'Configuration Média', cmp: 'configurationMedia', icon: 'mdi-folder-multiple-image' },
            // { label: 'Correspondance des liens', cmp: 'configurationLink', icon: 'mdi-download-network-outline' },
            // { label: 'Template par défault', cmp: 'configurationTemplates', icon: 'mdi-text-box-outline' },
            // { label: 'Configuration CMS', cmp: 'configurationCms', icon: 'mdi-book-cog-outline' },
        },
        isMobile() {
            return this.$vuetify.display.mobile
        },
    },
    components: {
        configurationBlog,
        configurationTemplates,
        configurationLink,
        configurationCms,
        configurationMedia
    },
    methods: {
        sendNewConfig(newConfig) {
            let that = this
            newConfig = JSON.parse(JSON.stringify(newConfig))

            for (const [key, value] of Object.entries(newConfig)) {
                if (typeof value == 'object') {
                    newConfig[key] = JSON.stringify(value)
                }
            }

            engine.update_group_config(this.groupInformation.id, newConfig, function () {
                that.getUserData()
            })
        },
        updateUserData(data, key) {
            let that = this
            engine.update_user_by_key(data, key, function () {
                that.closeSettings()
            })
        },
        closeSettings() {
            this.isSettingsModalOpen = false
            this.selectedSetting = undefined
            // this.getUserData()
        },
        selectSetting(item) {
            this.selectedSetting = item
            // this.isSettingsModalOpen = true
        }
    }
}
</script>