<template>
    <v-app class="bg">
      <router-view></router-view>
    </v-app>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      windowHeight: window.innerHeight,
      showHelp: false
    }
  },
  mounted() {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);

    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.windowHeight = window.innerHeight
    }
  }
}
</script>

<style>
@import "./style.css";
</style>