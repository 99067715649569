<template>
  <v-dialog v-model="isImageModalOpen" width="90%" max-width="750" persistent>
    <v-card class="bg">
      <v-btn @click="closeImageModal" style="position:absolute;right:5px;top:5px" icon density="compact">
        <v-icon color="red">
          mdi-close
        </v-icon>
      </v-btn>
      <v-col align=center style="font-weight: bold; font-size: 25px;color:white" class="pb-1">
        Ajouter une photo
      </v-col>
      <v-col>
        <v-text-field variant="solo" label="URL" hide-details v-model="newImageData.src">
        </v-text-field>
      </v-col>
      <v-col>
        <v-textarea variant="solo" label="ALT" auto-grow rows="1" hide-details v-model="newImageData.alt">
        </v-textarea>
      </v-col>
      <v-col>
        <v-row>
          <v-col cols="12">
            <v-text-field variant="solo" label="Longeur (width) en pixel" type="number" hide-details
              v-model="newImageData.maxWidth">
            </v-text-field>
          </v-col>
          <!-- <v-col cols="12">
            <v-text-field variant="solo" label="Hauteur (height) en pixel, risque de déformation de l'image"
              type="number" hide-details v-model="newImageData.height">
            </v-text-field>
          </v-col> -->
        </v-row>
      </v-col>
      <v-col align="center">
        <v-card class="px-2">
          <div style="font-weight: bold; font-size: 20px;" class="py-2">
            Positionement
          </div>
          <v-radio-group inline style="width: 100%;" v-model="newImageData.pos" variant="solo" align=center>
            <v-radio density="compact" style="width: 33.3%;" label="A Gauche" value="float:left;"></v-radio>
            <v-radio density="compact" style="width: 33.3%;" label="Au millieu"
              value="display:block;margin:auto;"></v-radio>
            <v-radio density="compact" style="width: 33.3%;" label="A droite" value="float:right;"></v-radio>
          </v-radio-group>
        </v-card>
      </v-col>
      <v-col align=center>
        <v-btn @click="addImage">
          Valider
        </v-btn>
      </v-col>
    </v-card>
  </v-dialog>
  <div v-if="editor" class="container">
    <div class="control-group">
      <v-col cols="12">
        <v-row>
          <v-col v-for="(item, i) in btnListe" :key="i" cols="" class="pa-0"
            style="border-right: solid 1px var(--C1);border-bottom: solid 1px var(--C1);">
            <v-menu v-if="item.isMenu" transition="slide-y-transition" variant="flat">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" variant="flat"
                  style="border-radius: 0px;background-color: white;color: var(--C1);width: 100%;">
                  <v-icon :size="item.iconSize">{{ item.icon }} </v-icon>
                </v-btn>
              </template>
              <v-btn v-for="(btn, idx) in item.values" :key="idx" variant="flat" style="border-radius: 0px;"
                @click="runFunction(item.function, btn.value)" :color="btn.color">
                <v-icon v-if="btn.icon" size="30"> {{ btn.icon }}</v-icon>
                {{
                  btn.label
                }}</v-btn>
            </v-menu>
            <v-btn v-else variant="flat" style="border-radius: 0px;background-color: white;color: var(--C1);width:100%"
              @click="runFunction(item.function, item.value)"
              :disabled="item.canBeDisabled ? !editor.can().chain().focus()[item.function.name]().run() : false">
              <v-icon :size="item.iconSize">{{ item.icon }} </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="" class="pa-0" style="border-right: solid 1px var(--C1);border-bottom: solid 1px var(--C1);">
            <v-btn @click="isImageModalOpen = true" variant="flat"
              style="border-radius: 0px;background-color: white;color: var(--C1);width:100%">
              <v-icon :size="30"> mdi-image-plus </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="" class="pa-0" style="border-bottom: solid 1px var(--C1);">
            <v-btn @click="toggleHtml" variant="flat"
              style="border-radius: 0px;background-color: white;color: var(--C1);width:100%">
              <v-icon :size="30"> mdi-file-code-outline </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </div>
    <v-col>
      <v-card class="" :style="showHtml ? 'background-color:#263238;color:white' : ''">
        {{ content }}
        <div v-if="showHtml">
          <v-textarea v-model="content" auto-grow rows="1" hide-details></v-textarea>
        </div>
        <div v-else class="">
          <editor-content style="height: 100vh; overflow: auto;" :editor="editor" />
        </div>
      </v-card>
    </v-col>
  </div>
</template>

<script>
import { Color } from '@tiptap/extension-color'
import ListItem from '@tiptap/extension-list-item'
import TextStyle from '@tiptap/extension-text-style'
import StarterKit from '@tiptap/starter-kit'
import { Editor, EditorContent } from '@tiptap/vue-3'
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Image from '@tiptap/extension-image';
import CustomInlineStyle from '../components/editorExtension/CustomInlineStyle';
import Link from '@tiptap/extension-link';
import CustomImage from '../components/editorExtension/customImg';

import {
  v4 as uuidv4
} from 'uuid';

export default {
  components: {
    EditorContent,
  },

  data() {
    return {
      newImageData: {},
      isImageModalOpen: false,
      showHtml: false,
      content: '',
      editor: null,
      btnListe: [
        {
          isMenu: false,
          function: {
            type: 'complex',
            name: 'toggleBold'
          },
          icon: 'mdi-format-bold',
          iconSize: '30',
        },
        {
          isMenu: false,
          function: {
            type: 'complex',
            name: 'toggleItalic'
          },
          icon: 'mdi-format-italic',
          iconSize: '30',
        },
        {
          isMenu: false,
          function: {
            type: 'complex',
            name: 'toggleUnderline'
          },
          icon: 'mdi-format-underline',
          iconSize: '30',
        },
        {
          isMenu: true,
          function: {
            type: 'complex',
            name: 'toggleHeading'
          },
          icon: 'mdi-format-header-pound',
          iconSize: '30',
          values: [
            { value: { level: 1 }, label: 'H1' },
            { value: { level: 2 }, label: 'H2' },
            { value: { level: 3 }, label: 'H3' },
            { value: { level: 4 }, label: 'H4' }
          ]
        },
        {
          isMenu: false,
          function: {
            type: 'complex',
            name: 'toggleBulletList'
          },
          icon: 'mdi-format-list-bulleted',
          iconSize: '30',
        },
        {
          isMenu: false,
          function: {
            type: 'complex',
            name: 'toggleStrike'
          },
          icon: 'mdi-format-strikethrough',
          iconSize: '30',
        },
        {
          isMenu: false,
          function: {
            type: 'complex',
            name: 'toggleOrderedList'
          },
          icon: 'mdi-order-numeric-ascending',
          iconSize: '30',
        },
        {
          isMenu: true,
          function: {
            type: 'complex',
            name: 'setColor'
          },
          icon: 'mdi-palette',
          iconSize: '30',
          values: [
            { value: 'black', color: 'black' },
            { value: 'white', color: 'white' },
            { value: 'red', color: 'red' },
            { value: 'purple', color: 'purple' },
          ]
        },
        {
          isMenu: false,
          function: {
            type: 'complex',
            name: 'undo'
          },
          icon: 'mdi-undo',
          iconSize: '30',
          canBeDisabled: true,
        },
        {
          isMenu: false,
          function: {
            type: 'complex',
            name: 'redo'
          },
          icon: 'mdi-redo',
          iconSize: '30',
          canBeDisabled: true,
        },
        {
          isMenu: true,
          function: {
            type: 'complex',
            name: 'setTextAlign'
          },
          icon: 'mdi-format-align-center',
          iconSize: '30',
          values: [
            { value: 'left', icon: 'mdi-format-align-left' },
            { value: 'center', icon: 'mdi-format-align-center' },
            { value: 'right', icon: 'mdi-format-align-right' },
            { value: 'justify', icon: 'mdi-format-align-justify' }
          ]
        },
      ]
    }
  },
  watch: {
    showHtml() {
      this.listenImageClick()
    },
    content(newHtml) {
      if (this.showHtml && this.editor)
        this.editor.commands.setContent(newHtml);
    }
  },
  mounted() {
    let that = this
    this.editor = new Editor({
      extensions: [
        Color.configure({ types: [TextStyle.name, ListItem.name] }),
        TextStyle.configure({ types: [ListItem.name] }),
        StarterKit,
        Underline,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
        Image,
        CustomInlineStyle,
        CustomImage,
        Link.configure({
          openOnClick: true,
          linkOnPaste: true,
        }),
      ],
      onUpdate({ editor }) {
        that.content = editor.getHTML();
        that.listenImageClick()
      },
      content: that.content,
    })
    this.listenImageClick()
  },
  methods: {
    closeImageModal() {
      this.isImageModalOpen = false
      this.newImageData = {}
    },

    findImage(id, callback) {
      // let parser = new DOMParser();
      // let doc = parser.parseFromString(this.content, 'text/html');
      // let image = doc.getElementById(id);

      // console.log("doc", doc);
      // console.log("image", image);

      // callback(image)
      console.log("editor dom", this.editor.view.dom);
      let serializer = new XMLSerializer();
      console.log("dom to str", serializer.serializeToString(this.editor.view.dom));
      

      let images = this.editor.view.dom.querySelectorAll('img')
      for (let image of images) {
        if (image.id == id) {
          callback(image)
          break;
        }
      }
    },
    listenImageClick() {
      console.log("listenImageClick");

      let that = this

      let parser = new DOMParser();
      let doc = parser.parseFromString(this.content, 'text/html');
      let contentsImages = doc.querySelectorAll('img')
      let idTab = []
      let images = this.editor.view.dom.querySelectorAll('img')

      images.forEach((image, idx) => {
        let id = uuidv4()
        idTab[idx] = id
        image.id = id

        if (image.isListened != true) {
          image.isListened = true
          image.addEventListener('click', (event) => {
            let clickedImage = event.target;
            that.handleImageClick(clickedImage);
          })
        }

        idTab.push(image.id)

      })

      contentsImages.forEach((image, idx) => {
        image.id = idTab[idx]
      })
      let serializer = new XMLSerializer();
      that.content = serializer.serializeToString(doc);
    },
    handleImageClick(image) {
      console.log("handleImageClick");

      let styleFormated = {}
      let styleFormatedTmp = image.style.cssText.split(';')

      for (let i = 0; i < styleFormatedTmp.length; i++) {
        let cssValue = styleFormatedTmp[i].split(':')
        if (cssValue.length == 2 && cssValue[0].length > 0)
          styleFormated[cssValue[0].trim()] = cssValue[1].trim()
      }

      this.newImageData = {
        src: image.src,
        id: image.id,
        alt: image.alt,
        maxWidth: styleFormated['max-width'] != undefined ? styleFormated['max-width'].replace('px', '') : undefined,
        height: styleFormated['height'] != undefined ? styleFormated['height'].replace('px', '') : undefined,
      }
      this.isImageModalOpen = true
    },
    addImage() {
      let that = this
      let style = `padding:1vw;width:100%;@@HEIGHT@@@@WIDTH@@@@POS@@`

      if (this.newImageData.height) style = style.replace('@@HEIGHT@@', 'height:' + this.newImageData.height + 'px;')
      else style = style.replace('@@HEIGHT@@', '')

      if (this.newImageData.maxWidth) style = style.replace('@@WIDTH@@', 'max-width:' + this.newImageData.maxWidth + 'px;')
      else style = style.replace('@@WIDTH@@', '')

      if (this.newImageData.pos) style = style.replace('@@POS@@', this.newImageData.pos + ';')
      else style = style.replace('@@POS@@', '')

      console.log("style", style);
      console.log("editor", this.editor);

      if (this.newImageData.id == undefined) {
        let img = `<img src='${this.newImageData.src}' alt='${this.newImageData.alt}' style='${style}' />`

        this.editor
          .chain()
          .focus()
          .insertContent(img)
          .run()
        this.listenImageClick()
      } else {
        let styleFormated = {}
        let styleFormatedTmp = style.split(';')

        for (let i = 0; i < styleFormatedTmp.length; i++) {
          let cssValue = styleFormatedTmp[i].split(':')
          if (cssValue.length == 2 && cssValue[0].length > 0)
            styleFormated[cssValue[0].trim()] = cssValue[1].trim()
        }

        console.log("styleFormated", styleFormated);
        console.log("newImageData", this.newImageData);


        this.findImage(this.newImageData.id, function (findedImage) {
          console.log("findedImage", findedImage);


          let parser = new DOMParser();
          let doc = parser.parseFromString(that.content, 'text/html');
          let contentsImages = doc.querySelectorAll('img')

          // if (styleFormated['max-width'] == undefined) findedImage.style.removeProperty('max-width');
          // else findedImage.style.maxWidth = styleFormated['max-width'];

          // if (styleFormated['height'] == undefined) findedImage.style.removeProperty('height');
          // else findedImage.style.height = styleFormated['height'];

          // if (styleFormated['float'] == undefined) findedImage.style.removeProperty('float');
          // else findedImage.style.float = styleFormated['float'];

          // if (styleFormated['display'] == undefined) findedImage.style.removeProperty('display');
          // else findedImage.style.display = styleFormated['display'];


          // if (styleFormated['margin'] == undefined) findedImage.style.removeProperty('margin');
          // else findedImage.style.margin = styleFormated['margin'];

          // findedImage.src = that.newImageData.src
          // findedImage.alt = that.newImageData.alt

          // findedImage.setAttribute('alt', that.newImageData.alt);
          // findedImage.setAttribute('src', that.newImageData.src);

          contentsImages.forEach(image => {
            console.log("searching");
            if (image.id == findedImage.id) {
              console.log("finded");

              if (styleFormated['max-width'] == undefined) findedImage.style.removeProperty('max-width');
              else image.style.maxWidth = styleFormated['max-width'];

              if (styleFormated['height'] == undefined) findedImage.style.removeProperty('height');
              else image.style.height = styleFormated['height'];

              if (styleFormated['float'] == undefined) findedImage.style.removeProperty('float');
              else image.style.float = styleFormated['float'];

              if (styleFormated['display'] == undefined) findedImage.style.removeProperty('display');
              else image.style.display = styleFormated['display'];


              if (styleFormated['margin'] == undefined) findedImage.style.removeProperty('margin');
              else image.style.margin = styleFormated['margin'];


              let serializer = new XMLSerializer();
              that.content = serializer.serializeToString(doc);
              that.listenImageClick()
            }
          })
        })
      }
      this.isImageModalOpen = false
      this.newImageData = {}
    },

    toggleHtml() {
      this.showHtml = !this.showHtml;
      if (this.showHtml) {
        this.content = this.editor.value.getHTML();
      } else {
        this.editor.commands.setContent(this.content);
      }
    },
    runFunction(functionData, param) {
      if (functionData.type == 'basic') {
        this[functionData.name](param)
      }
      else if (functionData.type == 'complex') {
        if (param) {
          this.editor.chain().focus()[functionData.name](param).run()
        } else
          this.editor.chain().focus()[functionData.name]().run()
      }
    }
  },
  beforeUnmount() {
    this.editor.destroy()
  },
}
</script>
