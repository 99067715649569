<template>
    <v-dialog v-model="closeData.model" width="auto">
        <v-card>
            <v-col align=center>
                <div class="my-2" style="font-weight: bold;">
                    {{ closeData.label }}
                </div>
                <v-row>
                    <v-col>
                        <v-btn style="background-color: red; color: white;" @click="closeData.model = false">
                            Non
                        </v-btn>
                    </v-col>
                    <v-col>
                        <v-btn style="background-color: green; color: white;" @click="closeData.callback">
                            OUi
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-card>
    </v-dialog>
    <v-dialog persistent v-model="isPropositionSelectionOpen" width="95%" max-width="1400">
        <!-- <v-btn style="position: absolute;right: 10px;" icon="mdi-close" color="red" density="compact"
            @click="isPropositionSelectionOpen = false"></v-btn> -->
        <blogArticleProposition align="center" :updateNewProposal="updateNewProposal" :sendNewConfig="sendNewConfig"
            :closeModal="function () { closeProposition() }" :localSelectedSite="localSelectedSite"
            :groupInformation="groupInformation" :preselectArticleListe="preselectArticleListe">
        </blogArticleProposition>
    </v-dialog>
    <!-- 54 -->
    <v-col>
        <v-row :style="{ 'height': isMobileXs ? getOperatingSystem == 'IOS' ? '67.5vh' : '74vh' : '80vh' }"
            style="overflow-y: auto;" >
            <v-col cols="12" align=center class="px-0 pt-0">
                <v-card style="border: solid 2px var(--C1)" variant="flat">
                    <div align="left" class="" style="color: var(--C1) !important">
                        <div :class="isMobileXs ? 'pt-2' : 'py-3'"
                            style="font-weight: bold;text-transform: uppercase;position:relative" align="center">
                            <div :style="{ 'font-size': isMobileXs ? '30px' : '40px' }">
                                <v-card variant="flat" style="color:var(--C1);text-transform: none;">
                                    Générer mon article
                                </v-card>
                            </div>
                            <div style="position:absolute;right:10px;top:10px">
                                <v-btn icon="mdi-cog" class="button" height="35" width="35" variant="flat"
                                    @click="isPropositionSelectionOpen = true"
                                    style="border-radius: 100% !important;"></v-btn>
                            </div>

                        </div>
                    </div>
                    <v-col class="pb-0">
                        <!-- :style="{ 'max-height': isMobileXs ? 'calc(100vh - 370px)' : 'calc(100vh - 390px)' }" -->
                        <v-row style="" justify=center align=center>
                            <v-col v-if="groupInformation && selectedKeyToGenerate && generatorIsLoading == false"
                                cols="12" class="pt-0 pb-0">
                                <v-card style="padding:0px 0px;padding-bottom: 15px;" variant="flat" color="white">
                                    <v-col class="local-scrollbar"
                                        :style="{ 'height': isMobileXs ? getOperatingSystem == 'IOS' ? '37.5vh' : '44vh' : '50vh' }"
                                        style="overflow: auto;background-color: #ECEFF1;border-radius: 5px;">
                                        <v-col cols="12" class="py-2" v-if="siteListe.length > 1">
                                            <v-card
                                                style="background-color: var(--C4);border-radius: 14px; border: solid 1px var(--C1)"
                                                variant="flat">
                                                <v-select label="Sélection du jauris" hide-details bg-color="#E8EAF6"
                                                    :items="siteListe" item-title="label" item-value="idx"
                                                    v-model="localSelectedSite" return-object
                                                    @update:model-value="changePlatform">
                                                </v-select>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="12" class="py-2"
                                            v-if="shopifyTemplate && selectedTemplate && localSelectedSite && shopifyTemplate['content'].filter((val) => val.type == localSelectedSite.platform).length > 1">
                                            <v-card
                                                style="background-color: var(--C4);border-radius: 14px; border: solid 1px var(--C1)"
                                                variant="flat">
                                                <v-select label="Sélection du template" hide-details bg-color="#E8EAF6"
                                                    :items="shopifyTemplate['content'].filter((val) => val.type == localSelectedSite.platform)"
                                                    item-title="title" v-model="selectedTemplate.content" return-object>
                                                </v-select>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="12" class="py-2"
                                            v-if="shopifyTemplate && selectedTemplate && localSelectedSite && shopifyTemplate['content'].filter((val) => val.platform == localSelectedSite.platform).length > 1">
                                            <v-card
                                                style="background-color: var(--C4);border-radius: 14px; border: solid 1px var(--C1)"
                                                variant="flat">
                                                <v-select label="Sélection d'un affichage" hide-details
                                                    bg-color="#E8EAF6"
                                                    :items="shopifyTemplate['display'].filter((val) => val.platform == localSelectedSite.platform)"
                                                    item-title="title" v-model="selectedTemplate.display" return-object>
                                                </v-select>
                                            </v-card>
                                        </v-col>
                                        <!-- selectedTemplate.value = {
                'length': shopifyTemplate.value['length'].find((val) => val.default_selected == true),
                'content': shopifyTemplate.value['content'].find((val) => val.default_selected == true)
            }; -->

                                        <v-col class="py-2"
                                            v-if="localSelectedSite && localSelectedSite.label && blogListe[localSelectedSite.label] && blogListe[localSelectedSite.label].length > 1">
                                            <v-card
                                                style="background-color: var(--C4);border-radius: 14px;border: solid 1px var(--C1);"
                                                variant="flat">
                                                <v-select class="" label="Site de publication*" bg-color="#E8EAF6"
                                                    :style="{ border: localCreationDataForm.post_compte_id.isValide == false ? 'solid 2px red' : '' }"
                                                    style="background-color: var(--C4);border-radius: 14px"
                                                    v-model="localCreationDataForm['post_compte_id'].value"
                                                    return-object hide-details
                                                    :items="blogListe[localSelectedSite.label]"></v-select>
                                            </v-card>
                                        </v-col>
                                        <v-col align="left" class="py-2">
                                            <!-- <BasicHoverInformation
                                    information="Écrire un sujet d'article ou en sélectionner un dans les sujets proposé.">
                                    <template v-slot:default> -->
                                            <v-card
                                                style="background-color: #E8EAF6;border-radius: 14px;border: solid 1px var(--C1);"
                                                variant="flat">
                                                <v-item-group selected-class="bg-primary" v-model="selectedArticleType">
                                                    <v-col class="pa-5">
                                                        <v-row>
                                                            <v-col v-for="elem in typeOfArticle" :key="elem" cols="12"
                                                                md="6" class="pa-2">
                                                                <v-item v-slot="{ isSelected, toggle }">
                                                                    <v-card class="pa-2" variant="flat"
                                                                        style="border: solid 2px var(--C1);font-weight: bold;border-radius: 5px !important;"
                                                                        :style="isSelected ? 'background-color:var(--C1);color:white' : ''"
                                                                        dark @click="toggle" align=center>
                                                                        {{ elem.label }}
                                                                    </v-card>
                                                                </v-item>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-item-group>
                                                <v-divider></v-divider>
                                                <v-combobox v-if="creationSelectedType == 0" class="" hide-details
                                                    no-data-text="Aucune recommendation disponible"
                                                    :label="typeOfArticle[selectedArticleType].title" bg-color="#E8EAF6"
                                                    v-model="chatgptQuestion" :items="preselectArticleListe">
                                                </v-combobox>
                                                <v-text-field label="Article de blog" v-if="creationSelectedType == 1"
                                                    class="" hide-details v-model="chatgptQuestion">
                                                </v-text-field>
                                                <!-- <v-checkbox class=""
                                                    v-if="groupInformation.id == '60d70f85-5cf1-4a6a-89fb-9279ceef78fb'"
                                                    :label="localCreationDataForm.isOptimized.label"
                                                    v-model="localCreationDataForm.isOptimized.value"
                                                    style="background-color: #E8EAF6;font-weight: bold;"
                                                    hide-details></v-checkbox>  -->
                                            </v-card>
                                            <!--  -->
                                            <!--  -->
                                            <v-card class="mt-4" v-if="selectedArticleType == 0"
                                                style="background-color: #E8EAF6;border-radius: 14px;border: solid 1px var(--C1);"
                                                variant="flat" align=center>
                                                <v-expansion-panels>
                                                    <v-expansion-panel>
                                                        <v-expansion-panel-title style="background-color: #E8EAF6"
                                                            class="px-3">
                                                            <template v-slot:default="{}">
                                                                <v-row no-gutters align="center" justify="center">
                                                                    <v-col class="d-flex justify-start" cols=""
                                                                        style="font-size: 15px;font-weight: bold;color: var(--C1);">
                                                                        Suggestions d'articles de blog
                                                                    </v-col>
                                                                    <v-col align=right class="px-0" cols="">
                                                                        <v-icon>
                                                                            mdi-menu-down
                                                                        </v-icon>
                                                                    </v-col>
                                                                </v-row>
                                                            </template>
                                                        </v-expansion-panel-title>
                                                        <v-expansion-panel-text style="background-color: #E8EAF6;"
                                                            class="pa-2">
                                                            <v-col
                                                                v-for="(article) in preselectArticleListe.slice(0, 4)"
                                                                cols="12" align=left class="pa-2 mb-2 hover-border-2"
                                                                style="background-color: white;border:solid 1px lightgrey"
                                                                :key="article.id"
                                                                @click="chatgptQuestion = article.title">
                                                                <v-row align="center" justify=center>
                                                                    <v-col cols="auto">
                                                                        <div style="font-size:12px">
                                                                            {{ article.title }}
                                                                            <v-span cols="auto"
                                                                                v-if="article.note && article.note.length > 0"
                                                                                style="font-size:12px">
                                                                                - {{ article.note }}
                                                                            </v-span>
                                                                        </div>
                                                                    </v-col>
                                                                    <v-col class="">
                                                                        <v-row align=center>
                                                                            <v-col v-for="category in article.category"
                                                                                :key="category" cols="auto"
                                                                                class="px-1">
                                                                                <v-card
                                                                                    v-if="localSelectedSite.chatgpt_proposal_group.
                                                                                        find((val) => val.id == category)"
                                                                                    :color="localSelectedSite.chatgpt_proposal_group.
                                                                                        find((val) => val.id == category).color"
                                                                                    class="px-2 py-1" variant="flat"
                                                                                    style="font-size:11px;font-weight:bold">
                                                                                    <v-card class="px-1"
                                                                                        style="color:black;background-color:rgba(255,255,255,0.7)"
                                                                                        variant="flat">
                                                                                        {{
                                                                                            localSelectedSite.chatgpt_proposal_group.
                                                                                                find((val) => val.id ==
                                                                                                    category).label }}
                                                                                    </v-card>
                                                                                </v-card>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </v-col>
                                                                </v-row>
                                                                <!-- <v-card class="pa-3 hover-border-2"
                                                                            @click="chatgptQuestion = article.title"
                                                                            variant="flat"
                                                                            style="border:solid 1px lightgrey">
                                                                            <v-span style="font-size: 12px; color:grey"
                                                                                class="pa-0"
                                                                                v-if="article.category && article.category.length > 0">
                                                                            </v-span>
                                                                        </v-card> -->
                                                            </v-col>
                                                        </v-expansion-panel-text>
                                                    </v-expansion-panel>
                                                </v-expansion-panels>
                                            </v-card>
                                            <div v-if="selectedArticleType == 0">
                                                <div
                                                    v-if="localSelectedSite.proposal_information && localSelectedSite.proposal_information.days_between_proposal && proposalIsLoading == false && creationSelectedType == 0 && userInformation.admin_lvl >= 2 && getNbrDayDiff >= localSelectedSite.proposal_information.days_between_proposal">
                                                    <v-btn style="width: 100%;" class="mt-4 button  " variant="tonal"
                                                        @click="setNewProposal">Nouvelle
                                                        propositions</v-btn>
                                                </div>
                                                <div class="mt-4" align="center"
                                                    v-if="localSelectedSite.proposal_information && localSelectedSite.proposal_information.days_between_proposal && getNbrDayDiff < localSelectedSite.proposal_information.days_between_proposal">
                                                    <v-chip class="py-2 px-4"
                                                        style="font-weight: bold; background-color: #E8EAF6;border: solid 1px var(--C1);border-radius: 14px;color: black;"
                                                        variant="flat">
                                                        Prochaine proposition d'articles dans : {{
                                                            localSelectedSite.proposal_information.days_between_proposal -
                                                            getNbrDayDiff }} J
                                                    </v-chip>
                                                </div>
                                            </div>
                                        </v-col>
                                        <v-col class="py-2">
                                            <v-row>
                                                <v-col
                                                    v-if="shopifyTemplate.length.filter((val) => val.show == true && val.platform == localSelectedSite.platform).length > 1"
                                                    cols="12" class="pb-1">
                                                    <v-card
                                                        style="background-color: var(--C4);border-radius: 14px;border: solid 1px var(--C1);"
                                                        variant="flat">
                                                        <v-select hide-details bg-color="#E8EAF6"
                                                            :items="shopifyTemplate.length.filter((val) => val.show == true && val.platform == localSelectedSite.platform)"
                                                            item-value="idx" label="Longueur"
                                                            v-model="selectedTemplate['length']" return-object>
                                                        </v-select>
                                                    </v-card>
                                                </v-col>
                                                <v-col v-if="showAllOptions" cols="12" class="pb-1">
                                                    <v-card
                                                        style="background-color: var(--C4);border-radius: 14px;border: solid 1px #26A69A;"
                                                        variant="flat">
                                                        <v-text-field hide-details label="Auteur" bg-color="#E0F2F1"
                                                            v-model="localCreationDataForm.author.value">
                                                        </v-text-field>
                                                    </v-card>
                                                </v-col>
                                                <!-- <v-col v-if="showAllOptions" class="pb-1">
                                                    <v-card
                                                        style="background-color: var(--C4);border-radius: 14px;border: solid 1px #26A69A;"
                                                        variant="flat">
                                                        <v-text-field
                                                            style="background-color: var(--C4);border-radius: 14px;"
                                                            chips label="Requête cible" hide-details bg-color="#E0F2F1"
                                                            v-model="localKeyWords" closable-chips></v-text-field>
                                                    </v-card>
                                                </v-col> -->
                                                <v-col cols="12" class="pb-0"
                                                    v-if="showAllOptions && localSelectedSite.link_connection && localSelectedSite.link_connection.length > 0 && (getLinksFormInformation && getLinksFormInformation.value.length != 0)">
                                                    <v-card
                                                        style="background-color: #E0F2F1;border-radius: 14px;border: solid 1px #26A69A;"
                                                        variant="flat" class="pa-3">
                                                        <div align="left" class="pa-2 pt-0" style="color: grey;">
                                                            Sélection des liens à utiliser
                                                        </div>
                                                        <v-row class="pa-1">
                                                            <v-col v-for="key in getLinksFormInformation.value"
                                                                :key="key.value" class="pa-2" cols="6"
                                                                :style="{ 'display': localSelectedSite.link_connection.filter((val) => val.value == key.value).length == 0 ? 'none' : '' }">
                                                                <v-card variant="flat" class="pa-2" height="100%">
                                                                    <div class="pl-1 mb-2">
                                                                        <v-chip
                                                                            :style="{ 'background': key.background }"
                                                                            style="color:white">
                                                                            {{ key.label }}
                                                                        </v-chip>
                                                                    </div>
                                                                    <v-autocomplete style="width: 100%; "
                                                                        density="compact"
                                                                        :items="localSelectedSite.link_connection.filter((val) => val.value == key.value)"
                                                                        multiple item-title="key"
                                                                        v-model="selectedLinks[key.value]"
                                                                        no-data-text="Aucun lien disponible"
                                                                        return-object hide-details
                                                                        :disabled="localSelectedSite.link_connection.filter((val) => val.value == key.value).length == 0">
                                                                        <template v-slot:selection="{ item, index }">
                                                                            <div v-if="index < 2" class="my-1">
                                                                                <span>{{ item.title }}</span>,
                                                                            </div>
                                                                            <span v-if="index === 2"
                                                                                class="text-grey text-caption align-self-center">
                                                                                (+{{ selectedLinks[key.value].length - 2
                                                                                }}
                                                                                Autre{{
                                                                                    selectedLinks[key.value].length > 3 ?
                                                                                        's' : ''
                                                                                }})
                                                                            </span>
                                                                        </template>
                                                                    </v-autocomplete>
                                                                    <v-divider></v-divider>
                                                                    <v-checkbox
                                                                        :disabled="localSelectedSite.link_connection.filter((val) => val.value == key.value).length == 0"
                                                                        hide-details v-model="checkBoxLink[key.value]"
                                                                        @change="changeCheckBoxLink(localSelectedSite.link_connection.filter((val) => val.value == key.value), key.value)"
                                                                        :label="'Tout' +
                                                                            selectedLinks && selectedLinks[key.value] && selectedLinks[key.value].length == localSelectedSite.link_connection.filter((val) => val.value == key.value).length ? 'Tout désélectionner' : 'Tout sélectionner'"
                                                                        density="compact"></v-checkbox>

                                                                </v-card>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card>
                                                </v-col>
                                                <!-- <v-col cols="12" class="pb-0 mt-1" v-if="showAllOptions">
                                        TEMPLATE MODIFICATION
                                        <v-card
                                            style="background-color:  #E0F2F1;border-radius: 14px; border: solid 1px #26A69A"
                                            variant="flat" class="pa-2">
                                            <v-row>
                                                <v-col v-for="elem in selectedTemplate.content.html" :key="elem.label"
                                                    cols="auto">
                                                    <v-card class="pa-2"
                                                        @click="localCreationDataForm['html'].value = elem">
                                                        {{ elem.label }}
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </v-card>
                                    </v-col> -->
                                            </v-row>
                                        </v-col>
                                    </v-col>
                                    <v-divider class="mx-1 my-2"></v-divider>
                                </v-card>
                            </v-col>
                            <v-col v-else cols="12" style="z-index: 2;">
                                <BasicLoading :stepTitle="stepTitle" :stepNum="stepNum" :stepNumMax="stepNumMax">
                                </BasicLoading>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" class="px-1 ml-6 py-0 mt-2">
                        <v-row class="pl-4">
                            <v-col cols="auto" class="pt-0 px-1">
                                <v-card variant="flat" class="pa-2 px-4"
                                    style="background-color: #E8EAF6;border: solid 1px var(--C1);border-radius: 14px;">Obligatoire</v-card>
                            </v-col>
                            <v-col cols="auto" class="pt-0 px-1">
                                <v-card variant="flat" class="pa-2 px-4"
                                    style="background-color: #E0F2F1;border: solid 1px var(--C1);border-radius: 14px;">Facultatif</v-card>
                            </v-col>
                        </v-row>
                        <v-checkbox hide-details v-model="showAllOptions" label="Options avancées"></v-checkbox>
                    </v-col>
                    <v-col cols="12" align=center class="pt-0 pb-2">
                        <v-btn
                            v-if="groupInformation.article_month_generated + 1 <= groupInformation.article_month_generated_max"
                            class="button" :disabled="!chatgptQuestion || chatgptQuestion.length < 0"
                            @click="run_creation" style="color:white;background-color: var(--C1);">
                            {{ validationLabel }}
                        </v-btn>
                        <v-card v-else style="background-color:red; color: white; font-weight: bold;" variant="flat"
                            class="pa-1">
                            Vous avez dépassé votre notre de génération d'article nous contacter pour plus
                            d'information.
                        </v-card>
                    </v-col>
                </v-card>
            </v-col>
        </v-row>
    </v-col>
    <v-snackbar v-model="snackbar.model" :color="snackbar.color" style="font-weight: bold;">
        {{ snackbar.label }}
    </v-snackbar>
</template>

<script>
import {
    ref,
    onMounted,
    watch
} from 'vue'
import engine from '@/core/index'
import moment from 'moment'
import {
    v4 as uuidv4
} from 'uuid';
import BasicLoading from '../basic/basic-loading.vue';
// import BasicHoverInformation from '../basic/basic-hover-information.vue';
import blogArticleProposition from './blog-article-proposition.vue';

export default {
    props: {
        getOperatingSystem: {},
        siteListe: {},
        windowHeight: {},
        localDataForm: {},
        selectedSite: {},
        blogListe: {},
        groupInformation: { required: true },
        userInformation: {},
        showAllPage: {},
        getLinksFormInformation: {
        },
        getUserData: {
            type: Function
        },
        setLocalDataFrom: {
            type: Function
        },
        setSelectedSite: {
            type: Function
        },
        closeModal: {
            type: Function
        },
        changeTablePage: {
            type: Function
        },
        validationFunction: {
            type: Function
        },
        updateData: {
            type: Function
        },
        getSiteListe: {
            type: Function
        },
        validationLabel: {}
    },
    data() {
        return {
            selectedArticleType: 0,
            typeOfArticle: [
                {
                    label: "Générer à partir d'un titre d'article de blog",
                    title: "Titre de l'article de blog",
                },
                {
                    label: "Générer à partir d'une requête cible",
                    title: "Requête cible",
                },
            ],
            proposalIsLoading: false,
            closeData: {},
            isPropositionSelectionOpen: false,
            localKeyWords: [],
            showAllOptions: false,
        }
    },
    setup(props) {
        const selectedKeyToGenerate = ref(undefined);
        const localCreationDataForm = ref(undefined);
        const localSelectedSite = ref(undefined);
        const selectedTemplate = ref(undefined);
        const snackbar = ref({});
        const selectedLinks = ref({});
        const checkBoxLink = ref({});
        const generatorIsLoading = ref(false);
        const stepTitle = ref(undefined);
        const stepNum = ref(0);
        const stepNumMax = ref(8);
        const chatgptQuestion = ref(undefined);
        const preselectArticleListe = ref([]);
        const shopifyTemplate = ref({});
        const creationSelectedType = ref(0);

        watch(localSelectedSite, function (val) {
            if (val.type) {
                creatPreSelectedArticleType();
            }
        });

        watch(
            () => selectedLinks,
            () => {
                let value = [0, 1, 2, 3]
                let valueData = []

                for (let i = 0; i < value.length; i++) {
                    if (selectedLinks.value[value[i]]) {
                        let valTab = selectedLinks.value[value[i]].filter((val) => val.value == value[i])
                        valueData = valueData.concat(valTab)

                        if (valTab.length == localSelectedSite.value.link_connection.filter((val) => val.value == i).length) {
                            checkBoxLink.value[value[i]] = true
                        }
                        else {
                            checkBoxLink.value[value[i]] = false
                        }
                    }
                }

                localCreationDataForm.value.links_redirect.value = valueData
            },
            { deep: true }
        )


        const creatPreSelectedArticleType = function () {
            if (localSelectedSite.value && localSelectedSite.value.chatgpt_proposal_group) {
                preselectArticleListe.value = JSON.parse(JSON.stringify(localSelectedSite.value.chatgpt_proposal));
                let listeCategory = localSelectedSite.value.chatgpt_proposal_group.map((val) => val.id)
                for (let i = 0; i < preselectArticleListe.value.length; i++) {
                    let preselectArticleListeTmp = [];
                    if (preselectArticleListe.value[i].category) {
                        for (let a = 0; a < preselectArticleListe.value[i].category.length; a++)
                            if (listeCategory.includes(preselectArticleListe.value[i].category[a]))
                                preselectArticleListeTmp.push(preselectArticleListe.value[i].category[a])
                        preselectArticleListe.value[i].category = preselectArticleListeTmp
                    }
                }
            }
            else
                preselectArticleListe.value = [];
        };
        onMounted(() => {
            initPage()
        });

        const initData = function () {
            localCreationDataForm.value = JSON.parse(JSON.stringify(props.localDataForm));
            localSelectedSite.value = JSON.parse(JSON.stringify(props.selectedSite));
        }

        const initPage = function () {
            initData()
            creatPreSelectedArticleType();
            shopifyTemplate.value = props.groupInformation.templates;
            selectedTemplate.value = {
                'length': shopifyTemplate.value['length'].find((val) => val.default_selected == true),
                'content': shopifyTemplate.value['content'].find((val) => val.default_selected == true),
                'display': shopifyTemplate.value['display'].find((val) => val.default_selected == true)
            };


            if (localSelectedSite.value.platform != selectedTemplate.value.content.type) {
                selectedTemplate.value.content = shopifyTemplate.value['content'].find((val) => val.type == localSelectedSite.value.platform)
            }

            if (chatgptQuestion.value == undefined)
                chatgptQuestion.value = preselectArticleListe.value[0];
            if (props.showAllPage == false) {
                selectedKeyToGenerate.value = localCreationDataForm.value['title'];
            }


            if (localSelectedSite.value.link_connection)
                for (let i = 0; i < localSelectedSite.value.link_connection.length; i++) {
                    let val = localSelectedSite.value.link_connection[i]
                    if (selectedLinks.value[val.value] == undefined)
                        selectedLinks.value[val.value] = []
                    selectedLinks.value[val.value].push(val)
                }

            localCreationDataForm.value['html'].value = selectedTemplate.value.content.html[0]
        }

        const generateData = function () {
            localCreationDataForm.value.user.value = props.userInformation
            localCreationDataForm.value.group.value = props.groupInformation
            localCreationDataForm.value.title.value = chatgptQuestion.value

            if (chatgptQuestion.value.title == undefined)
                chatgptQuestion.value = { title: chatgptQuestion.value }

            let dataToServer = {
                dataForm: localCreationDataForm.value,
                selectedTemplate: selectedTemplate.value,
                selectedSite: localSelectedSite.value,
                chatgptQuestion: chatgptQuestion.value,
            }


            console.log('dataToServer', dataToServer);

            if (dataToServer.selectedTemplate.content.type == 'shopify') {
                engine.creat_shopify_article_from_server(dataToServer, function () {
                })
                setTimeout(function () {
                    props.updateData()
                }, 3000);
                props.changeTablePage(0, 'Génération de votre article est en cours, temps estimé entre 5 et 9 minutes.', 'green')
            }
            else if (dataToServer.selectedTemplate.content.type == 'instagram') {
                engine.creat_instagram_publication_from_server(dataToServer, function () {
                })
                setTimeout(function () {
                    props.updateData()
                }, 3000);
                props.changeTablePage(0, 'Génération de votre poste est en cours, temps estimé entre 5 et 9 minutes.', 'green')
            }
        };

        return {
            stepTitle,
            chatgptQuestion,
            generateData,
            stepNumMax,
            stepNum,
            preselectArticleListe,
            shopifyTemplate,
            generatorIsLoading,
            selectedKeyToGenerate,
            selectedTemplate,
            localSelectedSite,
            localCreationDataForm,
            creationSelectedType,
            selectedLinks,
            checkBoxLink,
            creatPreSelectedArticleType,
            snackbar,
            initPage,
            initData
        };
    },
    watch: {
        selectedArticleType(val) {
            console.log("selectedArticleType", val);
            if (val == 0)
                this.localCreationDataForm.isOptimized.value = false
            else if (val == 1)
                this.localCreationDataForm.isOptimized.value = true
        },
        isPropositionSelectionOpen() {
            this.localSelectedSite = JSON.parse(JSON.stringify(this.selectedSite));
        },
        localSelectedSite(val) {
            if (this.selectedTemplate['length'] && this.selectedTemplate['length'].platform != val.platform)
                this.selectedTemplate.length = this.shopifyTemplate.length.find((val2) => val2.show == true && val2.platform == val.platform)
        },
        localKeyWords(val) {
            this.localCreationDataForm.key_words.value = val
            // let localTab = []
            // this.localCreationDataForm.key_words.value = ""

            // if (val.length > 5) {
            //     val.pop()
            // }

            // for (let i = 0; i < val.length; i++) {
            //     localTab.push(val[i])
            // }

            // if (localTab.length > 0){
            //     this.localCreationDataForm.key_words.value = localTab.join('@@')
            // }

        },
        creationSelectedType(val) {
            if (val == 1) {
                this.chatgptQuestion = ""
            } else {
                this.chatgptQuestion = this.preselectArticleListe[0]
            }
        },
        selectedTemplate: {
            handler() {
                let that = this;
                this.clickSelected = true;
                setTimeout(function () {
                    that.clickSelected = false;
                }, 100);
            },
            deep: true
        }
    },
    components: {
        BasicLoading,
        // BasicHoverInformation,
        blogArticleProposition
    },
    methods: {
        closeProposition() {
            this.isPropositionSelectionOpen = false
            // this.getUserData()
        },
        changePlatform(val) {
            this.setSelectedSite(val)
        },
        run_creation() {
            let that = this

            this.setCloseModal('Valider ma demande de génération ?', function () {
                that.generateData()
                that.closeData.model = false
            })
        },

        setCloseModal(label, callback) {
            this.closeData = {
                label,
                model: true,
                callback
            }
        },
        updateNewProposal(newConfig) {
            let that = this
            this.isPropositionSelectionOpen = false
            this.preselectArticleListe = newConfig
            this.updateConfigs(newConfig)
            this.getUserData(function () {
                that.getSiteListe()
                that.initData()
            })
            // that.getUserData(function () {
            //     // that.getSiteListe()
            // })

        },

        changeCheckBoxLink(items, tabIdx) {
            if (this.selectedLinks[tabIdx] == undefined) {
                this.selectedLinks[tabIdx] = items
            } else {
                if (this.selectedLinks[tabIdx].length == items.length)
                    this.selectedLinks[tabIdx] = []
                else
                    this.selectedLinks[tabIdx] = items
            }
        },
        sendNewConfig(newConfig) {
            let that = this
            newConfig = JSON.parse(JSON.stringify(newConfig))

            for (const [key, value] of Object.entries(newConfig)) {
                if (typeof value == 'object') {
                    newConfig[key] = JSON.stringify(value)
                }
            }

            engine.update_group_config(this.groupInformation.id, newConfig, function () {
                that.getUserData(function () {
                    that.getSiteListe()
                    that.initData()
                })
            })
        },
        updateConfigs(newProposal) {
            let localGroupInformation = JSON.parse(JSON.stringify(this.groupInformation))
            let key = this.localSelectedSite.platform

            for (let i = 0; i < localGroupInformation.web_site_data[key].length; i++) {
                let elem = localGroupInformation.web_site_data[key][i]
                if (elem.blog_id == this.localSelectedSite.blog_id) {
                    elem.chatgpt_proposal = newProposal
                    elem.proposal_information.last_update = moment().format('MM/DD/YYYY')
                }
            }

            this.sendNewConfig(localGroupInformation)
        },
        setNewProposal() {
            let that = this

            this.closeData = {
                label: 'Valider la création de nouvelle proposition',
                model: true,
                callback: function () {
                    that.localSelectedSite.proposal_information.last_update = moment().format('MM/DD/YYYY')
                    that.proposalIsLoading = true
                    engine.getNewProposal(that.localSelectedSite.blog_id, that.localSelectedSite.words_liste, that.selectedTemplate['content'].prompt.new_article_proposal, that.groupInformation.assistant_id, that.groupInformation.id, function (data) {
                        if (data.state == 200) {
                            let newProposal = data.data.map((val) => ({ id: uuidv4(), title: val }))
                            let finalProposal = that.preselectArticleListe.concat(newProposal)
                            that.preselectArticleListe = finalProposal
                            that.updateConfigs(finalProposal)
                        }
                    })
                    that.closeData = {}
                }
            }
        }
    },
    computed: {
        isMobileXs() {
            return this.$vuetify.display.xs
        },
        isMobile() {
            return this.$vuetify.display.mobile
        },
        getNbrDayDiff() {
            if (this.selectedSite.proposal_information && this.selectedSite.proposal_information.last_update)
                return moment().diff(this.selectedSite.proposal_information.last_update, 'days')
            else
                return undefined
        }
    }
}
</script>

<style>
.drop-zone {
    width: 50%;
    margin: 50px auto;
    background-color: green;
    padding: 10px;
    min-height: 10px;
}

drag-el {
    background-color: blue;
    color: white;
    padding: 5px;
    margin-bottom: 10px;
}
</style>