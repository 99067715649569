import { createRouter, createWebHistory } from 'vue-router'

import dashboard from '@/pages/page-dashboard'
import login from '@/pages/page-login'

import test from '@/pages/page-test'
import accenToken from '@/pages/page-acces-token'


const routes = [{
        path: '/',
        name: 'login',
        component: login
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: dashboard
    },
    {
        path: '/get_access_token',
        name: 'acces token',
        component: accenToken
    },
    {
        path: '/test',
        name: 'test',
        component: test
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router