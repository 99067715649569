<template>
    <div>
        
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    mounted() {
        let query = this.$route.query

        if (query.code) {
            localStorage.setItem('code', query.code)
        } else
            localStorage.setItem('code', undefined)

        this.$router.replace({ 'query': null });
        this.$router.push({ name: 'dashboard', query: { "show": 5 } })
    }
}
</script>