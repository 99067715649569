import { Image } from '@tiptap/extension-image';
import { mergeAttributes } from '@tiptap/core';

const CustomImage = Image.extend({
    addAttributes() {
        return {
            ...this.parent?.(),
            style : {
                default: null,
                parseHTML: element => element.getAttribute('style'),
                renderHTML: attributes => {
                    return {
                        style: attributes.style || '',
                    };
                },
            },
        };
    },

    renderHTML({ HTMLAttributes }) {
        return ['img', mergeAttributes(HTMLAttributes)];
    },
});

export default CustomImage;