<template>
    <v-row style="background-color: var(--C4);" v-if="localUsernformation">
        <v-col cols="12" align=left style="background-color: var(--C1);color: white;">
            <h2>
                Paramètre entreprise
            </h2>
            <div style="position: absolute;right: 10px;top:5px;">
                <v-icon color="red" style="cursor: pointer;" @click="close">mdi-close-circle</v-icon>
            </div>
        </v-col>
        <v-col cols="12" v-for="(elem, i) in platforms" :key="i">
            <v-card v-if="localUsernformation[elem.key]" style="background-color: var(--C1);">
                <v-card-title style="color: white;">
                    {{ elem.title }}
                </v-card-title>
                <v-col v-for="(info, i) in localUsernformation[elem.key]" :key="info" cols="12" class="pa-1">
                    <v-card style="background-color: var(--C4);">
                        <v-card-title>
                            Site : {{ i + 1 }}
                        </v-card-title>
                        <div style="position: absolute; right: 10px; top: 10px;">
                            <v-icon style="cursor: pointer;" color="red"
                                @click="removeBusiness(elem.key, i)">mdi-minus-circle</v-icon>
                        </div>
                        <v-col>
                            <v-row>
                                <v-col v-for="subKey in elem.keyToShow" :key="subKey.key" cols="6" md="6" class="pa-2">
                                    <v-text-field :label="subKey.label" v-model="info[subKey.key]" variant="solo"
                                        hide-details>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-card>
                </v-col>
                <v-col cols="12" align=center class="pt-1">
                    <v-icon size="50" color="green" style="cursor: pointer;" @click="addBusiness(elem.key)">
                        mdi-plus-circle
                    </v-icon>
                </v-col>
            </v-card>
        </v-col>
        <v-col align=center>
            <v-btn class="my-4 button" @click="updateAllBusiness()">
                Mettre à jour
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
export default {
    data() {
        return {
            localUsernformation: undefined,
            platforms: [
                {
                    title: 'Shopify',
                    key: 'shopify_token',
                    keyToShow: [{
                        label: 'Nom',
                        key: 'label'
                    }, {
                        label: 'Thème',
                        key: 'type'
                    }, {
                        label: 'URL',
                        key: 'url'
                    }, {
                        label: 'Mot descriptif (séparé par une virgule informatique,science,...)',
                        key: 'words_liste'
                    }, {
                        label: 'Token shpat',
                        key: 'compte_token'
                    }],
                }
            ]
        }
    },
    props: {
        updateUserData: { type: Function },
        userInformation: {}
    },
    mounted() {
        this.localUsernformation = JSON.parse(JSON.stringify(this.userInformation))
        for (let i = 0; i < this.platforms.length; i++) {
            this.localUsernformation[this.platforms[i].key] = JSON.parse(this.localUsernformation[this.platforms[i].key])
        }
    },
    methods: {
        updateAllBusiness() {
            for (let i = 0; i < this.platforms.length; i++) {
                this.updateUserData(JSON.stringify(this.localUsernformation[this.platforms[i].key]), this.platforms[i].key)
            }
        },
        removeBusiness(key, index) {
            this.localUsernformation[key].splice(index, 1)
        },
        addBusiness(key) {
            this.localUsernformation[key].push({})
        },
        close() {
            this.$emit('close')
        }
    }
}
</script>

<style></style>