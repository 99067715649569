<template>
    <v-col>
        <v-row v-if="localUsernformation" align="center" justify="center">
        <v-col class="py-2" v-if="shopifyTemplate">
            <h3 class="py-6">
                Choisissez le template que vous voulez utiliser par défaut
            </h3>
            <v-row>
                <v-col cols="12">
                    <v-card color="" variant="flat" class="pa-1 bg">
                        <v-select hide-details variant="solo" label="Nombre de mot" :items="shopifyTemplate.length.filter((val) => val.show == true)"
                            item-value="idx" v-model="selectedTemplate['length']" return-object >
                        </v-select>
                    </v-card>
                </v-col>
                <v-divider></v-divider>
                <v-col cols="12" class="">
                    <v-card style="background-color:white;border-radius: 14px;border: solid 4px var(--C1);" variant="flat">
                        <v-col>
                            <v-row style="height: 45vh;">
                                <v-col v-for="(elem, idx) in shopifyTemplate['content']" cols="auto" :key="idx" align=center>
                                    <v-card @click="setSelectedElem(idx)"  class="pa-6" variant="flat"
                                    style="border: solid 4px var(--C1);border-radius: 10px;font-weight: bold;"
                                        :style="elem.idx == selectedTemplate['content'].idx ? 'background-color:var(--C4) !important;color:black !important' : 'background-color:var(--C1) !important;color:white !important'">
                                        {{ elem.title }}
                                        <!-- <div align=center style="position: relative;
                                        ">
                                            <v-switch readonly v-model="elem.default_selected" style="padding-left: 39%;"
                                                hide-details inset color="green"></v-switch>
                                        </div> -->
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-card>
                    <v-col align=center>
                            <v-btn class="button mt-4" variant="flat" @click="updateConfig()">
                                Enregistrer
                            </v-btn>
                        </v-col>
                </v-col>
            </v-row>
        </v-col>

    </v-row>

    </v-col>
</template>

<script>

// import shopifyTemplate from "@/template/shopify.json"

export default {
    data() {
        return {
            shopifyTemplate: {},
            selectedTemplate: {},
            localUsernformation: undefined,
            localGroupInformation: undefined
        }
    },
    props: {
        updateUserData: { type: Function },
        userInformation: {},
        groupInformation: {},
        sendNewConfig: { type: Function },
    },
    computed: {
        isMobile() {
            return this.$vuetify.display.mobile
        },
    },
    mounted() {
        this.localGroupInformation = JSON.parse(JSON.stringify(this.groupInformation))
        this.shopifyTemplate = this.groupInformation.templates
        this.selectedTemplate = { 'length': this.shopifyTemplate['length'].find((val) => val.default_selected == true), 'content': this.shopifyTemplate['content'].find((val) => val.default_selected == true) }
        this.localUsernformation = JSON.parse(JSON.stringify(this.userInformation))
    },
    methods: {
        setSelectedElem(idx) {
            for (let i = 0; i < this.shopifyTemplate.content.length; i++) {
                this.shopifyTemplate.content[i].default_selected = false
            }
            this.shopifyTemplate.content[idx].default_selected = true
            this.selectedTemplate.content = this.shopifyTemplate.content[idx]
        },
        updateConfig() {
            for (let i = 0; i < this.shopifyTemplate.length.length; i++) {
                this.shopifyTemplate.length[i].default_selected = false
                if (this.shopifyTemplate.length[i].idx == this.selectedTemplate.length.idx) {
                    this.shopifyTemplate.length[i].default_selected = true
                }
            }

            this.localGroupInformation.templates = this.shopifyTemplate
            this.sendNewConfig(this.localGroupInformation)
        }

    }
}
</script>

<style></style>