<template>
    <v-row style="background-color: var(--C4);" v-if="localUsernformation" class="settings_link" justify="center"
        align="center">
        <v-col>
            <h3 class="py-6">
                Renseignez les mots-clefs sur lesquels vous souhaitez associer un lien.
            </h3>
            <v-card v-if="localBlogListe && localBlogListe.length > 0" class="pa-1 bg" variant="flat"
                style="border-radius: 10px;">
                <v-expansion-panels v-model="selectedSite">
                    <v-expansion-panel v-for="(elem, idx) in localBlogListe" :key="idx" class="bg"
                        style="color:white;border: none;">
                        <v-expansion-panel-title>
                            <v-row no-gutters>
                                <v-col class="text--secondary" cols="8">
                                    <v-fade-transition leave-absolute>
                                        {{ elem.platform.toUpperCase() }} - {{ elem.label }}
                                    </v-fade-transition>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text class="pa-0" style="background-color: transparent;border: none;"
                            variant="flat" elevation="0">
                            <v-card variant="flat" class="pa-3" style="border: solid 1px lightgray; border-radius: 5px;"
                                v-if="elem">
                                <v-item-group mandatory v-model="selectedLinkType">
                                    <v-container>
                                        <v-row>
                                            <v-col v-for="linktype in linkData" :key="linktype.value"
                                                :cols="linktype.cols" class="px-0">
                                                <v-item v-slot="{ isSelected, toggle }">
                                                    <v-btn variant="flat" class="d-flex align-center"
                                                        :style="isSelected ? 'background:var(--BG);color:white;' : 'background-color:#ECEFF1'"
                                                        style="width:100%;text-transform: none !important;border-radius: 0%;"
                                                        @click="toggle">
                                                        {{ linktype.label }}
                                                        <div v-if="linktype.icon">
                                                            <BasicHoverInformation position="left"
                                                                :information="linktype.info">
                                                                <template v-slot:default>
                                                                    <v-icon :color="linktype.color"
                                                                        :size="linktype.size">
                                                                        {{
                                                                            linktype.icon }}
                                                                    </v-icon>
                                                                </template>
                                                            </BasicHoverInformation>
                                                        </div>
                                                    </v-btn>
                                                </v-item>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-item-group>
                                <v-checkbox density="compact" class="pb-2" style="font-weight: bold;"
                                    :label="'Activer la correspondance automatique de lien ' + linkData[selectedLinkType].keyToShow"
                                    hide-details
                                    v-model="elem.use_link_connection[linkData[selectedLinkType].key]"></v-checkbox>
                                <v-card class="mb-4 bg pa-1" variant="flat"
                                    v-if="linkData[selectedLinkType].value == 0 && internLinkData.urlListeByType">
                                    <v-autocomplete bg-color="white" hide-details label="Filtrer par catégorie"
                                        :items="Object.keys(internLinkData.urlListeByType)"
                                        v-model="selectedKeysToShow">
                                    </v-autocomplete>
                                </v-card>
                                <div style="overflow: scroll; width: 100%;white-space: nowrap"
                                    class="pa-2">
                                    <table style="text-overflow: clip;">
                                    <tr align="center">
                                        <th style="width: 30%;" v-if="selectedLinkType != 2 && selectedLinkType != 3">
                                            <div align=center>
                                                Ancre
                                            </div>
                                        </th>
                                        <th style="">
                                            <div align=center>
                                                URL
                                            </div>
                                        </th>
                                        <th style="width: 20%;" v-if="selectedLinkType == 0 || selectedLinkType == 1">
                                            <div align=center>
                                                REL
                                            </div>
                                        </th>
                                        <th style="">
                                            <div align=center>

                                            </div>
                                        </th>
                                    </tr>
                                    <tr v-for="(elem2, idx2) in elem.link_connection.filter((val) => val.value == selectedLinkType)"
                                        :key="idx2">
                                        <td v-if="selectedLinkType != 2 && selectedLinkType != 3">

                                            <v-text-field v-model="elem2.key" hide-details  menu-icon=""
                                                style="border: solid 1px lightgrey;border-radius: 10px;width: 100%; overflow: auto;">
                                            </v-text-field>
                                        </td>
                                        <td>
                                            <v-text-field v-if="selectedLinkType != 0" v-model="elem2.url" hide-details  menu-icon=""
                                                style="border: solid 1px lightgrey;border-radius: 10px;width: 100%; overflow: auto;">
                                            </v-text-field>
                                            <div v-else>
                                                <v-autocomplete v-if="internLinkData && internLinkData.finalUrlData"  menu-icon=""
                                                    v-model="elem2.url" style="width: 100%; overflow: auto;"
                                                    :items="internLinkData.finalUrlData.filter((val) => haveCommonElement(val.keys, selectedKeysToShow))"
                                                    hide-details>
                                                    <template v-slot:item="{ props, item }">
                                                        <v-list-item v-bind="props">
                                                            {{ item.value.url }}
                                                        </v-list-item>
                                                    </template>
                                                </v-autocomplete>
                                            </div>
                                        </td>
                                        <td v-if="selectedLinkType == 0 || selectedLinkType == 1">
                                            <v-select :items="relListe" v-model="elem2.rel" item-value="value"
                                                item-title="label" hide-details style="width: 100%; overflow: auto;" menu-icon="">
                                            </v-select>
                                        </td>
                                        <td style="background-color: white;">
                                            <div align=center>
                                                <v-icon color="red" style="cursor:pointer"
                                                    @click="removeLink(elem.link_connection, elem2)">mdi-trash-can</v-icon>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                                </div>
                                <v-col cols="12" align=right class=" pb-0">
                                    <v-btn @click="addLink(elem.link_connection)" variant="flat" class="button">
                                        <div style="font-size: 25px;" class="pr-2">
                                            +
                                        </div> Ajouter un lien
                                    </v-btn>
                                </v-col>
                            </v-card>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card>
            <v-card v-else class="pa-4" align="center">
                Configurer un CMS si vous voulez utiliser cette option de configuration.
            </v-card>
            <v-col v-if="localBlogListe && localBlogListe.length > 0" align=center cols="12">
                <v-btn class="my-4 button" variant="flat" @click="updateGroupLink()">
                    Enregistrer </v-btn>
            </v-col>
        </v-col>
    </v-row>
</template>

<script>

import engine from '@/core/index'
import BasicHoverInformation from '../basic/basic-hover-information.vue';

export default {
    data() {
        return {
            relListe: [
                {
                    value: 'nofollow',
                    label: 'nofollow',
                    description: 'une balise utilisée pour les liens vers du contenu non approuvé. Cette balise est utilisée quand on ne peut pas appliquer Sponsored et UGC.',
                },
                {
                    value: 'ugc',
                    label: 'ugc',
                    description: 'une balise utilisée pour les liens vers du contenu généré par les utilisateurs : commentaires, avis, posts, etc',
                },
                {
                    value: 'sponsored',
                    label: 'sponsored',
                    description: 'une balise utilisée pour les liens vers du contenu sponsorisé et payant.',
                },
            ],
            internLinkData: {},
            selectedSite: undefined,
            selectedLinkType: 0,
            selectedKeysToShow: [],
            localUsernformation: undefined,
            localLinkConnection: undefined,
            localBlogListe: undefined,
            localGroupInformation: undefined,
        }
    },
    props: {
        getLinksFormInformation: {},
        updateUserData: { type: Function },
        userInformation: {},
        groupInformation: {},
        blogListe: {},
        sendNewConfig: { type: Function },
    },
    computed: {
        linkData() {

            let links = [
                {
                    label: "Liens internes",
                    value: 0,
                    key: 'interne',
                    keyToShow: 'interne',
                    cols: ''
                },
                {
                    label: "Liens externes",
                    value: 1,
                    key: 'externe',
                    keyToShow: 'externe',
                    cols: ''
                }, {
                    icon: 'mdi-block-helper',
                    label: "",
                    value: 2,
                    keyToShow: ' à bloqué',
                    key: 'blocked',
                    cols: 'auto',
                    color: 'red',
                    size: 20,
                    info: 'Liste des liens (domaine) qui ne doivent surtout pas être utilisé dans la génération d\'articles'
                }, {
                    icon: 'mdi-check-circle',
                    label: "",
                    value: 3,
                    keyToShow: ' à utiliser',
                    key: 'use',
                    cols: 'auto',
                    color: 'green',
                    size: 25,
                    info: 'Liste des liens (domaine) qui doivent être mis en avant et utiliser dans la génération d\'articles'
                }
            ]

            let filteredLink = []

            if (this.getLinksFormInformation.value)
                for (let i = 0; i < links.length; i++) {
                    if (this.getLinksFormInformation.value.find((val) => val.value == links[i].value))
                        filteredLink.push(links[i])
                }
            else
                return []

            return filteredLink
        },

        isMobile() {
            return this.$vuetify.display.mobile
        },
    },
    mounted() {
        this.localUsernformation = JSON.parse(JSON.stringify(this.userInformation))
        this.localBlogListe = JSON.parse(JSON.stringify(this.blogListe))
        this.localGroupInformation = JSON.parse(JSON.stringify(this.groupInformation))

        for (let i = 0; i < this.localBlogListe.length; i++) {
            if (this.localBlogListe[i].link_connection == undefined) {
                this.localBlogListe[i].link_connection = []
            }
        }

        this.selectedSite = 0
    },
    watch: {
        selectedSite(val) {
            let site = this.localBlogListe[val]
            let that = this
            let finalUrlData = []
            let finalKeyListe = []
            let urlListeByType = {}

            if (site)
                engine.get_url_liste_from_domaine(site.url, function (data) {
                    if (data.state == 200) {
                        let urlListe = data.data
                        for (let i = 0; i < urlListe.length; i++) {
                            let url = urlListe[i]
                            let keyUrl = urlListe[i].split(site.url)[1]
                            if (url != null && url.includes('/')) {
                                let keyListe = keyUrl.split('/').filter((val) => val.length > 0)
                                if (keyListe.length >= 2) {
                                    let long = keyListe.length
                                    let key = keyListe[long - 2]
                                    let value = keyListe[long - 1]
                                    if (urlListeByType[key] == undefined)
                                        urlListeByType[key] = []
                                    urlListeByType[key].push(value)
                                }
                                finalUrlData.push({
                                    title: keyUrl,
                                    value: url,
                                    keys: keyListe
                                })
                            }
                        }
                        that.internLinkData = { finalKeyListe, finalUrlData, urlListeByType }
                    } else {
                        that.internLinkData = { finalKeyListe, finalUrlData, urlListeByType }
                    }
                })
            else
                that.internLinkData = { finalKeyListe, finalUrlData }
        }
    },
    methods: {
        haveCommonElement(arr1, arr2) {
            if (arr2.length == 0)
                return true
            if (arr1.includes(arr2))
                return true
            return false
            // Créer un ensemble à partir du premier tableau
            // const set1 = new Set(arr1);

            // if (arr2.length == 0)
            //     return true
            // // Parcourir le deuxième tableau pour vérifier les éléments en commun
            // for (const elem of arr2) {
            //     if (set1.has(elem)) {
            //         return true;
            //     }
            // }

            // // Si aucun élément en commun n'est trouvé
            // return false;
        },
        updateGroupLink() {
            this.localGroupInformation.web_site_data = {}

            for (let i = 0; i < this.localBlogListe.length; i++) {
                for (let a = 0; a < this.localBlogListe[i].link_connection.length; a++)
                    if (this.localBlogListe[i].link_connection[a].value >= 2)
                        this.localBlogListe[i].link_connection[a].key = this.localBlogListe[i].link_connection[a].url

                if (this.localGroupInformation.web_site_data[this.localBlogListe[i].platform] == undefined) {
                    this.localGroupInformation.web_site_data[this.localBlogListe[i].platform] = []
                }
                this.localGroupInformation.web_site_data[this.localBlogListe[i].platform].push(this.localBlogListe[i])
            }

            this.sendNewConfig(this.localGroupInformation)
        },
        removeLink(allLinks, linkTab) {
            let pos = allLinks.findIndex((val) => val.key == linkTab.key && val.label == linkTab.label && val.url == linkTab.url)
            allLinks = allLinks.splice(pos, 1)
        },
        addLink(linkTab) {
            let localLinkData = this.linkData[this.selectedLinkType]
            linkTab.push({ key: undefined, url: undefined, rel: 'nofollow', label: localLinkData.label, value: localLinkData.value })
        },
        close() {
            this.$emit('close')
        }
    },
    components: {
        BasicHoverInformation
    }
}
</script>

<style></style>