<template>
    <v-row style="background-color: var(--C4);" v-if="localUsernformation" class="settings_link" justify="center"
        align="center">
        <v-col>
            <h3 class="py-6">
                Renseignez le mode de génération de contenu que vous souhaitez utiliser
             </h3>
            <v-card v-if="localBlogListe && localBlogListe.length > 0" class="pa-1 bg" variant="flat"
                style="border-radius: 10px;">
                <v-row>
                    <v-col v-for="(elem, idx) in localBlogListe" :key="idx">
                        <v-card variant="flat" class="pa-3" style="border: solid 1px lightgray; border-radius: 5px;"
                            v-if="elem">
                            <h3 class="mb-3" v-if="elem.platform">
                                {{ elem.platform.toUpperCase() }} - {{ elem.label }}
                            </h3>
                            <v-col>
                                <v-row  >
                                    <v-col v-for="(elem2, idx2) in elem.photo_management" :key="idx2" align="center">
                                        <v-card @click="setNewPhotoParam(elem, elem2)"  variant="flat" color="#EDEDED" class="pa-5" height="100%"
                                        :style="elem2.selected?'border:solid 3px var(--C1)' : ''"
                                        >
                                                <h4>
                                                    {{ elem2.label }}
                                                </h4>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
            <v-card v-else class="pa-4" align="center">
                Configurer un CMS si vous voulez utiliser cette option de configuration.
            </v-card>
            <v-col v-if="localBlogListe && localBlogListe.length > 0" align=center cols="12">
                <v-btn class="my-4 button" variant="flat" @click="setNewConfig()">
                    Enregistrer </v-btn>
            </v-col>
        </v-col>
    </v-row>
</template>

<script>
export default {
    data() {
        return {
            localUsernformation: undefined,
            localLinkConnection: undefined,
            localBlogListe: undefined,
            localGroupInformation: undefined,
        }
    },
    props: {
        updateUserData: {
            type: Function
        },
        userInformation: {},
        groupInformation: {},
        blogListe: {},
        sendNewConfig: {
            type: Function
        },
    },
    computed: {
        isMobile() {
            return this.$vuetify.display.mobile
        },
    },
    mounted() {
        this.localUsernformation = JSON.parse(JSON.stringify(this.userInformation))
        this.localBlogListe = JSON.parse(JSON.stringify(this.blogListe))
        this.localGroupInformation = JSON.parse(JSON.stringify(this.groupInformation))
    },
    methods: {
        setNewPhotoParam(elem, elem2) {
            for (let i = 0; i < elem.photo_management.length; i++) {
                elem.photo_management[i].selected = false
            }
            elem2.selected = true
        },
        setNewConfig() {
            this.localGroupInformation.web_site_data = {}

            for (let i = 0; i < this.localBlogListe.length; i++) {
                if (this.localGroupInformation.web_site_data[this.localBlogListe[i].platform] == undefined) {
                    this.localGroupInformation.web_site_data[this.localBlogListe[i].platform] = []
                }
                this.localGroupInformation.web_site_data[this.localBlogListe[i].platform].push(this.localBlogListe[i])
            }

            this.sendNewConfig(this.localGroupInformation)
        },
    }
}
</script>

<style></style>
