<template lang="">
    <v-col style=" position:relative" align=center class="px-0">
        <v-progress-circular
      :size="180"
      :width="5"
      color="var(--C1)"
      indeterminate
      style="position:fixed; top:40%;left:45%;font-weight:bold"
    >
        Chargement
    </v-progress-circular>
    </v-col>
</template>

<script>
export default {
    props: {
        stepTitle: {},
        stepNum: {},
        stepNumMax: {},
    }
}
</script>

<style lang="">

</style>
