import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'

import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';

import VueTableLite from 'vue3-table-lite'

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import router from './router/index.js'

import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'

import VueLazyload from 'vue-lazyload'

import VueTrumbowyg from 'vue-trumbowyg';
import 'trumbowyg/dist/ui/trumbowyg.css';

loadFonts()

const app = createApp(App);
const loadimage = require('../src/assets/giphy.webp')

app.use(vuetify)
app.use(router)
    // app.use(VueLazyload)
app.use(VueLazyload, {
    preLoad: 0,
    // error: errorimage,
    loading: loadimage,
    attempt: 50,
    listenEvents: ['scroll']
})

app.use(VueTrumbowyg);
app.component('EasyDataTable', Vue3EasyDataTable);
app.component('mavonEditor', mavonEditor);
app.component('TableLite', VueTableLite);
app.component('DatePicker', Datepicker);
app.mount('#app')