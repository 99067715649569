<template>
    <div align="left" class="bg py-3"
        style="background-color: var(--C1); cursor: pointer; z-index: 2;position: fixed; top: 0px;width: 100vw;height: 80px;">
        <v-col class="pt-2">
            <v-row justify="center" align=center>
                <v-col cols="auto" v-if="isMobile" class="ml-3 pr-0 pt-0">
                    <v-btn class="" size="25" icon="mdi-menu" variant="text" color="white"
                        @click="localDrawerIsOpen = true">
                    </v-btn>
                </v-col>
                <v-col cols="" align="left" style="cursor:default;" class="pt-0">
                    <v-img src="@/assets/logo_jauris_white_transparent.webp" @click="reloadSite()"
                        style="border: none;cursor:pointer" :height="isMobile ? '60px' : '60px'"
                        :width="isMobile ? '90px' : '200px'"></v-img>
                </v-col>
                <!-- <v-col v-if="isMobile && [0].includes(localSelectedView)" cols="auto" class="pt-0"> -->
                <v-col cols="auto" class="pt-0" v-if="groupInformation && groupInformation.post_month_max && !isMobile">
                    <v-row justify="center" align="center">
                        <v-vol class="pt-0">
                            <v-card variant="flat" class="px-4 py-3"
                                style="background-color: rgba(255, 255, 255, 0.1); color:white;font-weight: bold; border-radius: 40px;font-size: 18px; ">
                                Génération restante
                                ( {{ groupInformation.article_month_generated_max - groupInformation.article_month_generated
                                }} /
                                {{ groupInformation.article_month_generated_max }} )
                                <!-- <br>
                                Publication :
                                {{ (groupInformation.post_month) }} /
                                {{ groupInformation.post_month_max }} -->
                            </v-card>
                        </v-vol>
                    </v-row>
                </v-col>
                <v-col v-if="[0].includes(localSelectedView)" cols="auto" class="pt-0">
                    <v-card @click="updateData" class="buttonV2 pa-1" style="cursor: pointer;border-radius: 100%;">
                        <v-icon style="border-radius: 100%; font-size: 40px;">
                            mdi-refresh
                        </v-icon>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </div>
    <div v-if="isLoading == false" :style="{ 'background-color': isMobile ? '' : 'var(--C4)' }"
        style="padding: 0px; height: 100vh;">
        <drawer-dashboard :localDrawerIsOpen="localDrawerIsOpen" :getOperatingSystem="getOperatingSystem"
            :setLocalDrawer="function (val) { localDrawerIsOpen = val }" :blogListe="blogListe"
            :getUserData="getUserData" :groupInformation="groupInformation" :userInformation="userInformation"
            :setSelectedVew="setSelectedVew" :getLinksFormInformation="getLinksFormInformation"></drawer-dashboard>
        <div
            :style="{ 'padding-left': isMobile ? '0px' : '105px', 'padding-top': '80px', 'background': isMobile ? 'var(--BG)' : 'var(--C4)' }">
            <dataTable style="" v-if="blogListe && localSelectedView == 0" :updateData="updateData"
                :updateTableAndCloseEditionModal="updateTableAndCloseEditionModal"
                :getOperatingSystem="getOperatingSystem" :openModalCreation="openModalCreation"
                :windowHeight="windowHeight" :windowWidth="windowWidth"
                :user_squeduel_liste="filter_user_squeduel_liste" :getLinksFormInformation="getLinksFormInformation"
                :modalIsOpen="modalIsOpen" :set_search="set_search" :planificateurElements="planificateurElements"
                :getUserData="getUserData" :blogListe="blogListe" :groupInformation="groupInformation"
                :userInformation="userInformation">
            </dataTable>
            <settings-view v-if="localSelectedView == 2" :userInformation="userInformation"
                :groupInformation="groupInformation" :getUserData="getUserData"></settings-view>
            <album-view v-if="localSelectedView == 4" :windowHeight="windowHeight" :windowWidth="windowWidth"
                :getOperatingSystem="getOperatingSystem" :user_squeduel_liste="filter_user_squeduel_liste"
                :planificateurElements="planificateurElements" :groupInformation="groupInformation"
                style="background-color: white; height: 100vh;" :getUserData="getUserData">
            </album-view>
            <seo-view v-if="localSelectedView == 5" :windowHeight="windowHeight" :windowWidth="windowWidth"
                :user_squeduel_liste="filter_user_squeduel_liste" :planificateurElements="planificateurElements"
                :groupInformation="groupInformation" style="" :getUserData="getUserData">
            </seo-view>
        </div>
    </div>
    <div v-else align="center">
        <basic-loading></basic-loading>
    </div>
    <v-snackbar v-model="snackData.show" color="green" timeout="10000">
        <v-col style="font-size: 19px; font-weight: normal;" class="pa-1" align="center">
            {{ snackData.label }}
        </v-col>
    </v-snackbar>
</template>

<script>
import {
    ref,
    onMounted,
    // watch
} from 'vue'

import dataTable from '@/view/view-table.vue'
import SettingsView from '@/view/view-settings.vue'
import AlbumView from '@/view/view-album.vue'
import SeoView from '@/view/view-seo.vue'

import DrawerDashboard from '@/components/dashboard/dashboard-drawer.vue'
import BasicLoading from '@/components/basic/basic-loading.vue'

import engine from '@/core/index'
import {
    useRouter
} from 'vue-router'

export default {
    setup() {
        const snackData = ref({})
        const localSelectedView = ref(0)
        const router = useRouter()
        const userInformation = ref({})
        const groupInformation = ref({})
        const user_squeduel_liste = ref(undefined)
        const searh_value = ref(undefined)
        const isLoading = ref(true)
        const blogListe = ref(undefined)
        const modalIsOpen = ref({})
        const modalData = ref(undefined)
        const localDrawerIsOpen = ref(undefined)
        const windowHeight = ref(0)
        const windowWidth = ref(0)

        const planificateurElements = ref([{
            title: 'Shopify',
            key: 'Shopify',
            icon: "mdi-shopping",
            color: "#CFD8DC",
            modalKey: "creatShopifyArticle",
            img: 'shopify4.webp'
        },
        {
            title: 'Wordpress',
            key: 'Wordpress',
            icon: "mdi-shopping",
            color: "#CFD8DC",
            modalKey: "",
            img: 'wordpress.webp'
        },
        ])

        const updateTableAndCloseEditionModal = function () {
            getSquedueldata()
            modalIsOpen.value = {}
        }

        const getSquedueldata = function () {
            engine.get_user_squeduel_by_token(function (data) {
                if (data.state == 200) {
                    user_squeduel_liste.value = data.data
                }
            })
        }

        const getBlogListe = function () {
            blogListe.value = []

            for (const [key, value] of Object.entries(groupInformation.value.web_site_data)) {

                for (let i = 0; i < value.length; i++) {
                    let tmp = value[i]
                    tmp['platform'] = key
                    blogListe.value.push(tmp)
                }
            }
        }

        const getUserData = function (callback) {
            engine.get_user_info_by_token(function (data) {
                if (data.state == 200) {
                    userInformation.value = data.data[0]
                    userInformation.value.settings = JSON.parse(userInformation.value.settings)
                    engine.get_group_by_user_id(userInformation.value.group_id, function (data2) {
                        if (data2.state == 200) {
                            groupInformation.value = data2.data
                            groupInformation.value.templates = JSON.parse(groupInformation.value.templates)
                            groupInformation.value.users = JSON.parse(groupInformation.value.users)
                            groupInformation.value.web_site_data = JSON.parse(groupInformation.value.web_site_data)
                            groupInformation.value.album_data = JSON.parse(groupInformation.value.album_data)
                            getBlogListe()
                            if (callback != undefined) {
                                callback()
                            }
                        } else {
                            localStorage.clear()
                            router.push({
                                name: 'login'
                            })
                        }
                    })
                } else {
                    localStorage.clear()
                    router.push({
                        name: 'login'
                    })
                }
            })
        }

        onMounted(() => {
            engine.is_user_token_valid(function (data) {
                if (data.state == 200) {
                    getSquedueldata()
                    getUserData()
                    isLoading.value = false
                } else {
                    localStorage.clear()
                    router.push({
                        name: 'login'
                    })
                }
            })
        })

        return {
            user_squeduel_liste,
            modalIsOpen,
            modalData,
            updateTableAndCloseEditionModal,
            isLoading,
            userInformation,
            groupInformation,
            searh_value,
            localSelectedView,
            planificateurElements,
            getUserData,
            getSquedueldata,
            blogListe,
            snackData,
            localDrawerIsOpen,
            windowHeight,
            windowWidth
        }
    },
    computed: {
        getOperatingSystem() {
            var userAgent = navigator.userAgent || navigator.vendor || window.opera;

            // Windows Phone must come first because its UA also contains "Android"
            if (/windows phone/i.test(userAgent)) {
                return "Windows Phone";
            }

            if (/android/i.test(userAgent)) {
                return "Android";
            }

            // iOS detection from: http://stackoverflow.com/a/9039885/177710
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                return "IOS";
            }

            return "unknown";
        },
        getLinksFormInformation() {
            if (this.userInformation && this.userInformation.settings) {
                let settingsLinksTab = {
                    "show_link_interne": { value: 0, label: 'Lien interne', background: 'var(--BG)' },
                    "show_link_externe": { value: 1, label: 'Lien externe', background: 'var(--BG)' },
                    "show_green_liste": { value: 3, label: 'Lien mis en avant', background: 'green' },
                    "show_red_liste": { value: 2, label: 'Lien bloqué', background: 'red' },
                }

                let linksSettingsToShow = []

                for (const [key, value] of Object.entries(settingsLinksTab)) {
                    if (this.userInformation.settings[key] == true)
                        linksSettingsToShow.push(value)
                }

                return { value: linksSettingsToShow }
            } else
                return { value: [] }
        },
        isMobile() {
            return this.$vuetify.display.mobile
        },
        filter_user_squeduel_liste() {

            if (this.user_squeduel_liste && this.user_squeduel_liste.length != 0) {
                let filter_user_squeduel_liste = this.user_squeduel_liste

                // if (this.selectedBlog && this.selectedBlog.blog_id)
                //     filter_user_squeduel_liste = this.user_squeduel_liste.filter((val) => val.blog_id == this.selectedBlog.blog_id)
                // else
                //     filter_user_squeduel_liste = this.user_squeduel_liste

                if (this.searh_value == undefined) {
                    return filter_user_squeduel_liste
                } else {
                    filter_user_squeduel_liste = this.user_squeduel_liste.filter((val) => val.title.toUpperCase().includes(this.searh_value.toUpperCase()))
                    return filter_user_squeduel_liste
                }
            } else {
                return []
            }
        }
    },
    mounted() {
        let that = this
        this.onResize()
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
        this.onResize()

        setInterval(function () {
            that.updateData()
            // }, 60 * 1000);
        }, 120 * 1000);

        let query = this.$route.query

        if (query.show) {
            this.localSelectedView = query.show
        }

        this.$router.replace({ 'query': null });
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.onResize);
    },

    methods: {
        reloadSite() {
            // this.$router.go('/')
            location.reload()
        },
        updateData() {
            this.getSquedueldata()
            this.getUserData()
        },
        setSelectedVew(viewId) {
            this.localSelectedView = viewId
        },
        openModalCreation(modalKey) {
            this.modalIsOpen[modalKey] = true
        },
        set_search(value) {
            this.searh_value = value
        },
        onResize() {
            this.windowHeight = window.innerHeight
            this.windowWidth = window.innerWidth
        },
    },
    components: {
        SeoView,
        AlbumView,
        dataTable,
        SettingsView,
        DrawerDashboard,
        BasicLoading,
    }
}
</script>

<style></style>
