<template>
    <v-row style="background-color: var(--C4);" v-if="localUsernformation" justify="center" align="center">
        <v-col cols="12" align=center v-if="localBlogListe.length > 0">
            <h3 class="py-6" align="left"> Renseignez les thématiques sur lesquels vous souhaitez vous positionner.</h3>
            <v-card class="pa-1 bg" align="left" variant="flat">
                <v-card variant="flat" style="border: solid 1px lightgrey;" class="pa-2">
                    <h5 class="ma-3">
                        Thématique de site
                    </h5>
                    <div class="pa-3">
                        <v-select :items="localBlogListe" item-title="label" v-model="tagsBlogData" return-object>
                        </v-select>
                        <div v-if="tagsBlogData">
                            <v-row justify="left" align="left" class="pl-4">
                                <v-col v-for="(tags, idx) in tagsBlogData['words_liste'].split(',')" :key="idx"
                                    class="py-1 px-0" cols="auto">
                                    <v-chip class="mx-1" v-if="tags.replaceAll(' ', '').length != 0"
                                        style="cursor: pointer;" @click="removeTag(tagsBlogData, idx)">
                                        {{ tags }} <v-icon>mdi-close</v-icon>
                                    </v-chip>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                    <v-col>
                        <v-row align="center">
                            <v-col cols="">
                                <v-text-field label="Ajouter une thématique" v-model="newTag"
                                    style="border: solid 1px lightgrey;border-radius: 5px;" hide-details
                                    variant="underlined" class="pa-2"></v-text-field>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn height="66px" variant="flat" class="button" style="font-weight: bold;"
                                    @click="addTag(tagsBlogData, newTag)">
                                    Ajouter
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-card>
            </v-card>
            <v-col class="mt-3">
                <v-btn variant="flat" class="button" style="font-weight: bold;" @click="updateConfigs()">
                    Enregistrer
                </v-btn>
            </v-col>
        </v-col>
        <v-col v-else>
            <v-card class="pa-4" align="center">
                Configurer un CMS si vous voulez utiliser cette option de configuration.
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
export default {
    data() {
        return {
            tagsBlogData: undefined,
            localUsernformation: undefined,
            localBlogListe: undefined,
            localGroupInformation: undefined,
            newTag: undefined
        }
    },
    props: {
        userInformation: {},
        groupInformation: {},
        blogListe: {},
        sendNewConfig: {}
    },
    computed: {
        isMobile() {
            return this.$vuetify.display.mobile
        },
    },
    mounted() {
        this.localUsernformation = JSON.parse(JSON.stringify(this.userInformation))
        this.localBlogListe = JSON.parse(JSON.stringify(this.blogListe))
        this.localGroupInformation = JSON.parse(JSON.stringify(this.groupInformation))
        this.tagsBlogData = this.localBlogListe[0]
        if (this.tagsBlogData && this.tagsBlogData.words_liste == undefined) {
            this.tagsBlogData.words_liste = ""
        }
    },
    methods: {
        updateConfigs() {
            this.localGroupInformation.web_site_data = {}

            for (let i = 0; i < this.localBlogListe.length; i++) {
                if (this.localGroupInformation.web_site_data[this.localBlogListe[i].platform] == undefined) {
                    this.localGroupInformation.web_site_data[this.localBlogListe[i].platform] = []
                }
                this.localGroupInformation.web_site_data[this.localBlogListe[i].platform].push(this.localBlogListe[i])
            }

            this.sendNewConfig(this.localGroupInformation)
        },
        addTag(blog, newTag) {
            let findedBlog = this.localBlogListe.find((val) => val.id == blog.id && val.label == blog.label)
            let tmpListe = blog.words_liste.split(',')
            tmpListe.push(newTag)
            tmpListe = tmpListe.join(',')
            findedBlog.words_liste = tmpListe
            this.newTag = undefined
        },
        removeTag(blog, selectedTagIdx) {
            let findedBlog = this.localBlogListe.find((val) => val.id == blog.id && val.label == blog.label)
            let tmpListe = blog.words_liste.split(',')
            tmpListe.splice(selectedTagIdx, 1);
            tmpListe = tmpListe.join(',')
            findedBlog.words_liste = tmpListe
        },
        close() {
            this.$emit('close')
        }
    }
}
</script>

<style></style>