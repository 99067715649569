<template>
    <v-dialog v-if="isSettingsModalOpen == true" v-model="isSettingsModalOpen" width="90%" max-width="1200">
        <v-card>
            <v-col>
                <component :is="selectedSetting.cmp" :userInformation="userInformation" @close="closeSettings"
                    :updateUserData="updateUserData"></component>
            </v-col>
        </v-card>
    </v-dialog>
    <v-card class="pa-2" style="background-color: var(--C4);border-radius: 0px;" height="100vh">
        <v-col v-for="(elem, i) in settings" :key="i" class="pa-2">
            <v-card class="pa-6" style="background-color: var(--C1);color:white">
                <v-row justify="center">
                    <v-col>
                        <h3>
                            {{ elem.label }}
                        </h3>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn class="button" variant="text" style="background-color: white; color: var(--C1);"
                            @click="selectSetting(elem)">
                            Modifier
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
    </v-card>
</template>

<script>

import SettingsUser from "@/components/settings/settings-user.vue"
import SettingsBusiness from "@/components/settings/settings-business.vue"
import SettingsLink from "@/components/settings/settings-link.vue"
import SettingsSubscription from "@/components/settings/settings-subscription.vue"
import engine from "@/core/index"

export default {
    data() {
        return {
            isSettingsModalOpen: false,
            selectedSetting: undefined,
            settings: [
                { label: 'Mes informations personnelles', cmp: 'SettingsUser' },
                { label: 'Paramètres de mon entreprise', cmp: 'SettingsBusiness' },
                { label: 'Correspondance automatique de liens', cmp: 'SettingsLink' },
                { label: 'Gérer l\'abonnement', cmp: 'SettingsSubscription' },
            ]
        }

    },
    props: {
        getUserData: { type: Function },
        userInformation: {}
    },
    components: {
        SettingsUser,
        SettingsBusiness,
        SettingsLink,
        SettingsSubscription,
    },
    methods: {
        updateUserData(data, key) {
            let that = this
            engine.update_user_by_key(data, key, function () {
                that.closeSettings()
            })
        },
        closeSettings() {
            this.isSettingsModalOpen = false
            this.selectedSetting = undefined
            this.getUserData()
        },
        selectSetting(item) {
            this.selectedSetting = item
            this.isSettingsModalOpen = true
        }
    }
}
</script>