<template>
    <!-- <img style="position: absolute; top: 0px;width: 100vw;bottom: 0px; height: 100vh;" src="fond_login_3.jpg" > -->
    <!-- <div class="bg" style="position: absolute; top: 0px; height: 100vh;width: 100vw;"></div> -->
    <div>
        <div v-if="isLoading == false" style="height:100vh;">
            <!-- background-image: url('https://4kwallpapers.com/images/wallpapers/texture-dark-background-purple-3840x2160-3086.jpg');" -->
            <v-row justify="center" align="center">
                <v-col :style="{ 'margin-top': isMobile ? '16vh' : '20vh' }" style="" cols="11" sm="9" md="7" lg="4">
                    <v-card class="pa-6 pt-0" style="background-color:white;border-radius: 10px;"
                        v-if="isSuscribeView == false">
                        <v-col align="center" class="" style="height: 150px;">
                            <v-img width="350" lazy-src="@/assets/logo_jauris_dark_transparent.webp"
                                src="@/assets/logo_jauris_dark_transparent.webp"></v-img>
                        </v-col>
                        <v-col cols="12" class="pt-0">
                            <v-card variant="flat" class="mb-3">
                                <v-text-field class="login_btn" v-model="login_form.email" hide-details
                                    label="Identifiant"></v-text-field>
                            </v-card>
                            <v-card variant="flat">
                                <v-text-field cl type="password" v-model="login_form.password" hide-details
                                    class="login_btn" label="Mot de passe"></v-text-field>
                            </v-card>
                        </v-col>
                        <v-col v-if="isLoginError" cols="12">
                            <v-card color="red" class="pa-2" style="font-weight: bold;" align="center">
                                Identifiant incorrect
                            </v-card>
                        </v-col>
                        <v-col>
                            <v-row>
                                <v-col align="center" cols="">
                                    <v-btn class="button" style="border-radius: 8px;" variant="flat" @click="login()"
                                        :disabled="!isFormValid" height="50" width="100%">Se
                                        connecter</v-btn>
                                </v-col>
                                <!-- <v-col align="center" cols="6">
                                    <v-btn class="buttonRererse"
                                    
                                        style="border-radius: 8px;font-weight: bold;"
                                        variant="flat" @click="isSuscribeView = true" height="50"
                                        width="100%">S'inscrire</v-btn>
                                </v-col> -->
                            </v-row>
                        </v-col>
                    </v-card>
                    <v-card v-else class="pa-2" style="background-color:white">
                        <v-col align="center">
                            <v-img width="200" src="@/assets/logo_jauris_dark_transparent.webp"></v-img>
                        </v-col>
                        <v-col cols="12">
                            <v-row>
                                <v-col cols="6" class="pa-2">
                                    <v-card variant="flat" class="">
                                        <v-text-field class="login_btn" v-model="login_form.lastname" hide-details
                                            label="Nom" type="text"></v-text-field>
                                    </v-card>
                                </v-col>
                                <v-col cols="6" class="pa-2">
                                    <v-card variant="flat">
                                        <v-text-field type="text" v-model="login_form.firstname" hide-details
                                            class="login_btn" label="Prénom"></v-text-field>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" class="pa-2">
                                    <v-card variant="flat" class="">
                                        <v-text-field type="email" class="login_btn" v-model="login_form.email"
                                            hide-details label="Email"></v-text-field>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" class="pa-2">
                                    <v-card variant="flat" class="">
                                        <v-text-field type="text" class="login_btn" v-model="login_form.company_name"
                                            hide-details label="Nom de société"></v-text-field>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" class="pa-2">
                                    <v-card variant="flat" class="">
                                        <v-text-field type="tel" class="login_btn" v-model="login_form.phone"
                                            hide-details label="Numéro"></v-text-field>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" class="pa-2">
                                    <v-card variant="flat">
                                        <v-text-field type="password" v-model="login_form.password" hide-details
                                            class="login_btn" label="Mot de passe"></v-text-field>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col class="px-1">
                            <v-row>
                                <v-col align="center" cols="6">
                                    <v-btn style="background: var(--BG);color:white;border-radius: 8px;" variant="flat"
                                        @click="subscribe" width="200" :disabled="!isFormValid"
                                        height="50">S'inscrire</v-btn>
                                </v-col>
                                <v-col align="center" cols="6">
                                    <v-btn
                                        style="background: var(--C4);color:var(--C1);border-radius: 8px;font-weight: bold;"
                                        variant="flat" @click="isSuscribeView = false" width="200"
                                        height="50">Retour</v-btn>
                                </v-col>
                            </v-row>
                        </v-col>

                        <!-- <v-col v-if="isLoginError" cols="12">
                            <v-card color="red" class="pa-2" style="font-weight: bold;" align="center">
                                Identifiant incorrect
                            </v-card>
                        </v-col>
                        <v-col>
                            <v-row>
                                <v-col align="center" cols="6">
                                    <v-btn style="background: var(--BG);color:white;border-radius: 8px;" variant="flat"
                                        @click="login" :disabled="!isFormValid" height="50" width="200">Se
                                        connecter</v-btn>
                                </v-col>
                                <v-col align="center" cols="6">
                                    <v-btn
                                        style="background: var(--C4);color:var(--C1);border-radius: 8px;font-weight: bold;"
                                        variant="flat" @click="isSuscribeView = true" height="50" width="200">S'inscrire</v-btn>
                                </v-col>
                            </v-row>
                        </v-col> -->
                    </v-card>
                </v-col>
            </v-row>
        </div>
        <v-row v-else align="center" justify="center" style="height: 100vh;">
            <v-progress-circular :size="220" :width="5" color="white" indeterminate>

                <span style="font-weight: bold; color:white; font-size: 17px;" align="center"> 
                    Bienvenue <br>sur <br> Jauris
                </span>

            </v-progress-circular>
        </v-row>
    </div>

</template>

<script>

import engine from "@/core/index"
import { useRouter } from 'vue-router'
import { ref, onMounted } from 'vue'
import { computed } from "@vue/runtime-core"

export default {
    setup() {
        const router = useRouter()
        const isLoginError = ref(false)
        const isSuscribeView = ref(false)
        const isLoading = ref(true)
        const login_form = ref({
        })

        const login = function () {
            engine.user_login(login_form.value, function (data) {
                if (data.state == 200) {
                    localStorage.setItem('token', data.data.token)
                    router.push({ name: 'dashboard' })
                } else {
                    isLoginError.value = true
                    setTimeout(function () {
                        isLoginError.value = false
                    }, 4000)
                }
            })
        }

        const subscribe = function () {
            engine.user_subscribe(login_form.value, function (data) {
                if (data.state != 200) {
                    isSuscribeView.value = false
                    isLoginError.value = true
                    setTimeout(function () {
                        isLoginError.value = false
                    }, 4000)
                }
            })
        }

        const isFormValid = computed(function () {
            if (login_form.value.email && login_form.value.email.length > 0 && login_form.value.password && login_form.value.password.length > 0)
                return true
            return false
        })

        onMounted(() => {
            engine.is_user_token_valid(function (data) {
                if (data.state == 200) {
                    router.push({ name: 'dashboard' })
                } 
                // else {
                //     setTimeout(function () {
                        isLoading.value = false
                //     },
                //         1000)
                // }
            })
        })

        return { login_form, login, subscribe, isSuscribeView, isFormValid, isLoginError, isLoading }
    },
    computed: {
        isMobile() {
            return this.$vuetify.display.mobile
        }
    },
}

</script>

<style></style>