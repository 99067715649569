<template>
    <v-row style="background-color: var(--C4);" v-if="localUsernformation">
        <v-col cols="12" align=center>
            <div
                style="height: 150px; width: 150px; background-color: var(--C1);color: white; border-radius: 50%;padding-top: 41px;font-size: 50px;">
                {{ localUsernformation.firstname[0] }}
            </div>
            <div style="position: absolute;right: 10px;top:5px;">
                <v-icon color="red" style="cursor: pointer;" @click="close">mdi-close-circle</v-icon>
            </div>
        </v-col>
        <v-col md="6" cols="12">
            <v-text-field label="Nom" v-model="localUsernformation.lastname" variant="solo" hide-details readonly>

            </v-text-field>
        </v-col>
        <v-col md="6" cols="12">
            <v-text-field label="Prénom" v-model="localUsernformation.firstname" variant="solo" hide-details readonly>

            </v-text-field>
        </v-col>
        <v-col md="6" cols="12">
            <v-text-field label="Email" v-model="localUsernformation.email" variant="solo" hide-details readonly>

            </v-text-field>
        </v-col>
        <v-col md="6" cols="12">
            <v-text-field label="Téléphone" v-model="localUsernformation.phone" variant="solo" hide-details readonly>
            </v-text-field>
        </v-col>
        <!-- <v-col align=center>
            <v-btn class="my-4 button">
                Mettre à jour
            </v-btn>
        </v-col> -->
    </v-row>
</template>

<script>
export default {
    data(){
        return {
            localUsernformation:undefined
        }
    },
    props : {
        userInformation:{}
    },
    mounted(){
        this.localUsernformation = JSON.parse(JSON.stringify(this.userInformation))
    },
    methods: {
        close() {
            this.$emit('close')
        }
    }
}
</script>

<style></style>