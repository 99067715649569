<template>
    <v-row style="background-color: var(--C4);" v-if="localUsernformation" class="settings_link">
        <v-col cols="12" align=left style="background-color: var(--C1);color: white;">
            <h2>
                Correspondance automatique de liens
            </h2>
            <div style="position: absolute;right: 10px;top:5px;">
                <v-icon color="red" style="cursor: pointer;" @click="close">mdi-close-circle</v-icon>
            </div>
        </v-col>
        <v-col cols="12">
            <table>
                <tr>
                    <th>Ancre</th>
                    <th>URL de redirection</th>
                    <th>
                        <div align=center>
                            Supprimer
                        </div>
                    </th>
                </tr>
                <tr v-for="(elem, idx) in localUsernformation['link_connection']" :key="idx">
                    <td>
                        <v-text-field v-model="elem.key" hide-details variant="solo">
                        </v-text-field>
                    </td>
                    <td>
                        <v-text-field v-model="elem.url" hide-details variant="solo">
                        </v-text-field>
                    </td>
                    <td>
                        <div align=center>
                            <v-icon color="red" style="cursor:pointer" @click="removeLink(idx)">mdi-trash-can</v-icon>
                        </div>
                    </td>
                </tr>
            </table>
        </v-col>
        <v-col cols="12" align=center class="pt-1 pb-0">
            <v-icon size="50" color="green" style="cursor: pointer;" @click="addLink()">
                mdi-plus-circle
            </v-icon>
        </v-col>
        <v-col align=center cols="12">
            <v-btn class="my-4 button" @click="updateUserData(JSON.stringify(localUsernformation['link_connection']), 'link_connection')">
                Mettre à jour
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>

export default {
    data() {
        return {
            localUsernformation: undefined,
            dataToEdit: ['link_connection']
        }
    },
    props: {
        updateUserData :{ type:Function},
        userInformation: {}
    },
    mounted() {
        this.localUsernformation = JSON.parse(JSON.stringify(this.userInformation))
        for (let i = 0; i < this.dataToEdit.length; i++) {
            this.localUsernformation[this.dataToEdit[i]] = JSON.parse(this.localUsernformation[this.dataToEdit[i]])
        }
    },
    methods: {
        // updateUserData() {
        //     let that = this
        //     engine.update_user_by_key(JSON.stringify(this.localUsernformation['link_connection']), 'link_connection', function () {
        //         that.$emit('close')
        //     })
        // },
        removeLink(index) {
            this.localUsernformation['link_connection'].splice(index, 1)
        },
        addLink() {
            this.localUsernformation['link_connection'].push({ key: undefined, url: undefined })
        },
        close() {
            this.$emit('close')
        }
    }
}
</script>

<style></style>