import { Mark, mergeAttributes } from '@tiptap/core';

const CustomInlineStyle = Mark.create({
    name: 'customInlineStyle',

    addAttributes() {
        return {
            style: {
                default: null,
                parseHTML: element => element.getAttribute('style'),
                renderHTML: attributes => {
                    if (!attributes.style) {
                        return {};
                    }
                    return { style: attributes.style };
                },
            },
        };
    },

    parseHTML() {
        return [{
            tag: 'span[style]',
        }, ];
    },

    renderHTML({ HTMLAttributes }) {
        return ['span', mergeAttributes(HTMLAttributes), 0];
    },

    addCommands() {
        return {
            setCustomInlineStyle: style =>
                ({ commands }) => {
                    return commands.setMark(this.name, { style });
                },
            unsetCustomInlineStyle:
                () =>
                ({ commands }) => {
                    return commands.unsetMark(this.name);
                },
        };
    },
});

export default CustomInlineStyle;