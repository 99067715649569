<template>
    <v-card class="pa-2 pt-0" style="background-color: white;">
        <v-col>
            <v-btn @click="close()" style="position: absolute; right: 5px; top: 5px; z-index: 2;" size="30"
                icon="mdi-close" class="button"></v-btn>
            <v-row align=center>
                <v-col cols="12" md="6" sm="6" style="padding: 20px 40px; height: 753px;"
                    :style="'overflow:auto'">
                    <div style="color: var(--C1); font-size: 50px;font-weight: bold;">
                        FAQ
                    </div>
                    <div style="font-weight: bold;" class="pt-2 pb-6">
                        Qu'est-ce que Jauris et comment fonctionne notre outil de rédaction automatisée ?
                    </div>
                    <div>
                        Jauris est une solution de rédaction web automatisée destinée aux entreprises en ligne. Notre
                        outil utilise des technologies avancées d'intelligence artificielle pour générer des articles de
                        blog optimisés pour le SEO en un seul clic. Il vous suffit de sélectionner le sujet de votre
                        choix, et notre IA se charge de produire un contenu de haute qualité, et de le diffuser sur
                        votre site en ligne selon votre mise en page. Jauris peut aussi vous faire des recommandations
                        pertinentes de sujets à traiter. <br><br>
                        C’est donc un outil qui vous permettra une gestion autonome de votre blog SEO.
                    </div>
                    <div style="font-weight: bold;" class="py-6">
                        Comment Jauris garantit-il l'optimisation SEO des articles générés ?
                    </div>
                    <div>
                        Jauris intègre des algorithmes d'optimisation SEO qui analysent et intègrent les mots-clés
                        pertinents, les balises méta, les textes alternatifs des images, les liens internes et externes,
                        ainsi que d'autres facteurs essentiels pour le référencement naturel. Cela permet de s'assurer
                        que chaque article généré est conçu pour se classer favorablement sur les moteurs de recherche
                        comme Google.<br><br>
                        De plus, jauris saura parler de votre marque presque aussi bien que vous, ce qui permet de se
                        démarquer des concurrents qui proposent des contenus standardisés.
                    </div>
                    <div style="font-weight: bold;" class="py-6">
                        Quels sont les avantages de l'utilisation de jauris par rapport à une rédaction manuelle ?
                    </div>
                    <div>
                        Utiliser jauris pour votre blog SEO présente plusieurs avantages :<br><br>

                        <div>
                            <ul>
                                <li>Gain de temps : Génération d'articles en un seul clic.</li>
                                <li>Cohérence : Production de contenu homogène et de qualité constante.</li>
                                <li>Optimisation SEO : Articles conçus pour maximiser le référencement naturel.</li>
                                <li>Réduction des coûts : Moins de dépenses en ressources humaines pour la rédaction de
                                    contenu.</li>
                            </ul>
                        </div>
                    </div>
                    <div style="font-weight: bold;" class="py-6">
                        Comment jauris utilise-t-il l'intelligence artificielle pour créer du contenu ?
                    </div>
                    <div>
                        Jauris s'appuie sur des modèles de langage avancés qui ont été entraînés sur de vastes corpus de
                        données textuelles. Ces modèles peuvent comprendre et générer du texte en imitant la rédaction
                        humaine. En analysant les besoins spécifiques de chaque sujet, l'IA produit des articles
                        structurés, informatifs et pertinents.<br><br>
                        L’autre point important est que jauris apprend de votre marque, de son univers. De cette
                        manière, il saura faire des liens pertinents avec votre activité ou vos produits.
                    </div>
                    <div style="font-weight: bold;" class="py-6">
                        Est-il possible de personnaliser les articles générés par Jauris ?
                    </div>
                    <div>
                        Oui, jauris est capable d’assimiler autant de données que vous lui en fournirez. Plus il aura de
                        ressources, plus ses contenus seront riches et pertinents. Si vous rencontrez des difficultés à
                        personnaliser jauris pour votre blog, prenez contact avec notre service client qui se fera un
                        plaisir de vous accompagner.
                    </div>
                    <div style="font-weight: bold;" class="py-6">
                        Comment puis-je m'assurer que les articles générés sont uniques et exempts de plagiat ?
                    </div>
                    <div>
                        Jauris utilise des techniques de génération de contenu uniques qui minimisent le risque de
                        plagiat. De plus, nous intégrons des outils de vérification de l'originalité pour garantir que
                        chaque article produit est unique et conforme aux normes éthiques en matière de contenu.
                    </div>
                    <div style="font-weight: bold;" class="py-6">
                        Quel support est disponible si j'ai des questions ou des problèmes avec Jauris ?
                    </div>
                    <div>
                        Nous offrons un support complet à nos utilisateurs. Vous pouvez nous contacter via notre service
                        client par e-mail (contact@jauris.com) et par téléphone (06 98 66 91 00).
                        Nous nous tenons à votre disposition pour vous accompagner du lundi au vendredi de 9h à 18h.<br><br>
                    </div>
                </v-col>
                <v-divider vertical v-if="isMobile == false"></v-divider>
                <v-col cols="10" md="6" sm="6" align="center"
                    style="font-size: 20px;font-weight: bold;padding: 0px 90px;">
                    <v-icon>mdi-phone-outline</v-icon>
                    <div class="py-3">
                        0426250303
                    </div>
                    <v-icon>mdi-email-outline</v-icon>
                    <div class="py-3">
                        contact@studio-bind.com </div>
                    <v-icon>mdi-map-marker-outline</v-icon>
                    <div class="py-3">
                        106 Rue du Président Edouard Herriot
                        69002 Lyon </div>
                    <v-icon>mdi-clock-outline</v-icon>
                    <div class="py-3">
                        Du lundi au vendredi : 9h - 18h </div>
                </v-col>
            </v-row>
        </v-col>
    </v-card>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    props: {
        close: { type: Function }
    },
    computed: {
        isMobile() {
            return this.$vuetify.display.mobile
        },
    }
}
</script>