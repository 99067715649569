<template>
  <div style="position: absolute;right: 5px;top:5px;z-index: 2;">
    <v-btn icon="mdi-close-circle-outline" class="button" @click="closeModal()" density="compact"
      style="border-radius: 100% !important;">
    </v-btn>
  </div>

  <v-dialog v-model="isImageModalOpen" width="90%" max-width="750" persistent>
    <v-card class="bg">
      <v-btn @click="closeImageModal" style="position:absolute;right:5px;top:5px;z-index:4" icon density="compact">
        <v-icon color="red">
          mdi-close
        </v-icon>
      </v-btn>
      <v-col align=center style="font-weight: bold; font-size: 25px;color:white;" class="pb-1">
        Photo édition
      </v-col>
      <v-col>
        <v-text-field variant="solo" label="URL" hide-details v-model="newImageData.src">
        </v-text-field>
      </v-col>
      <v-col>
        <v-textarea variant="solo" label="ALT" auto-grow rows="1" hide-details v-model="newImageData.alt">
        </v-textarea>
      </v-col>
      <v-col>
        <v-row>
          <v-col cols="12">
            <v-text-field variant="solo" label="Longeur (width) en pixel" type="number" hide-details
              v-model="newImageData.maxWidth">
            </v-text-field>
          </v-col>
          <!-- <v-col cols="12">
            <v-text-field variant="solo" label="Hauteur (height) en pixel, risque de déformation de l'image"
              type="number" hide-details v-model="newImageData.height">
            </v-text-field>
          </v-col> -->
        </v-row>
      </v-col>
      <v-col align="center">
        <v-card class="px-2">
          <div style="font-weight: bold; font-size: 20px;" class="py-2">
            Positionement
          </div>
          <v-radio-group inline style="width: 100%;" v-model="newImageData.pos" variant="solo" align=center>
            <v-radio density="compact" style="width: 33.3%;" label="A Gauche" value="float:left;"></v-radio>
            <v-radio density="compact" style="width: 33.3%;" label="Au millieu"
              value="display:block;margin:auto;"></v-radio>
            <v-radio density="compact" style="width: 33.3%;" label="A droite" value="float:right;"></v-radio>
          </v-radio-group>
        </v-card>
      </v-col>
      <v-col align=center>
        <div v-if="newImageData.pos == undefined || (newImageData.src == undefined || newImageData.src.length == 0)">
          <v-chip style="background-color: red; color:white; font-weight: bold;">
            L'URL et le positionement sont obligatoire !
          </v-chip>
        </div>
        <v-btn v-else @click="addImage"
          :disabled="newImageData.pos == undefined || (newImageData.src == undefined || newImageData.src.length == 0)">
          Valider
        </v-btn>
      </v-col>
    </v-card>
  </v-dialog>
  <v-dialog v-model="dialogIsOpen['date']" width="auto" persistent>
    <v-col>
      <v-card>
        <v-col align="center" class="pb-1">
          <h3>
            Choisir une date et une horaire de publication
          </h3>
        </v-col>
        <v-divider class="mx-2 my-2"></v-divider>
        <v-col>
          <v-row justify="center" align=center>
            <v-col>
              <input type="datetime-local" v-model="localEditionDataForm.publication_datetime.value">
              {{ localEditionDataForm.publication_datetime.value }}
            </v-col>
            <v-col align="center" cols="">
              <DatePicker v-model="localEditionDataForm.publication_date.value" inline auto-apply datetime
                :enable-time-picker="false" locale="fr">
              </DatePicker>
              {{ localEditionDataForm.publication_date.value }}
            </v-col>
            <v-col align="center" cols="">
              <input class="inputTime" type="time" v-model="localEditionDataForm.publication_time.value">
              {{ localEditionDataForm.publication_time.value }}
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-card class="pa-2" variant="tonal" align="center">
            {{ formateDate(localEditionDataForm.publication_date.value, 'LL') }}
            à
            {{ localEditionDataForm.publication_time.value }}
          </v-card>
        </v-col>
        <v-col align="center">
          <v-btn @click="dialogIsOpen['date'] = false"
            :disabled="localEditionDataForm.publication_datetime.value == undefined ? true : localEditionDataForm.publication_datetime.value.length > 0 ? false : true">Valider</v-btn>
        </v-col>
      </v-card>
    </v-col>
  </v-dialog>
  <!-- <v-col style="" class="bg"
      v-if="localEditionDataForm && blogListe && localSelectedSite && blogListe[localSelectedSite.label] && typeof localEditionDataForm['post_compte_id'].value == 'object'"> -->
  <v-col style="position:relative" class="bg local-scrollbar" v-if="localEditionDataForm && localSelectedSite">
    <v-row style="background: var(--BG);">
      <v-col :style="{ 'min-height': isMobile ? '' : isPosted ? '100vh' : '92.5vh' }" style="position: relative;" md="3"
        cols="12" class="px-2  " align=center>
        <div :style="!isMobileXs ? 'position: sticky;top:0px' : ''">
          <v-card class="pa-1">
            <v-img
              :style="{ border: localEditionDataForm.image_path.isValide == false ? 'solid 2px red' : 'solid 1px transparent' }"
              v-if="localEditionDataForm.image_path.value != undefined" @click="chooseImage" max-height="260"
              height="260" max-width="260" min-width="100%" :src="localEditionDataForm.image_path.value" />
            <div v-else @click="chooseImage"
              :style="{ border: localEditionDataForm.image_path.isValide == false ? 'solid 2px red' : '' }"
              style="background-color:white;max-height:37vh;min-height: 32vh; border-radius: 5px;padding-top: 8vh;cursor:pointer">
              <v-icon icon="mdi-camera" color="#757575" size="90px"></v-icon>
              <h1 style="color:#757575">Séléctionner une image</h1>
            </div>
            <v-file-input ref="file_upload" accept="image/*" @update:modelValue="setNewPhoto"
              style="visibility: hidden;height: 0px;">
            </v-file-input>
          </v-card>
          <div style="" class="mb-2">
            <v-col class="px-0 pb-0"
              v-if="localEditionDataForm && localEditionDataForm.metadata.value.title != undefined && localSelectedSite != undefined">
              <v-card class="card  py-4" align="left" variant="flat">
                <div class="px-3">
                  <v-textarea class="text-input-blue" label="Meta title" rows="1" auto-grow bg-color="white"
                    hide-details v-model="localEditionDataForm.metadata.value.title">
                  </v-textarea>
                  <div class="pt-1 px-4" style="color: #4CAF50;font-size: 9px;"
                    v-if="blogListe && localSelectedSite && localEditionDataForm && blogListe[localSelectedSite.label]">
                    {{ selectedSite.url }}/blogs/{{ selectedSite.label }}/{{
                      localEditionDataForm.title.value.toLowerCase().replaceAll(' ', '-') }}
                  </div>
                </div>
                <v-textarea label="Meta description" rows="1" auto-grow class="mt-0 pt-0 px-3" bg-color="white"
                  hide-details v-model="localEditionDataForm.metadata.value.description">
                </v-textarea>
              </v-card>
            </v-col>
            <v-col class="px-0 pb-0" v-if="localEditionDataForm && localEditionDataForm.author">
              <v-card class="pa-0 card" variant="flat" style="border: solid 2px white;">
                <v-text-field class="pa-0" label="Auteur" bg-color="white" hide-details
                  v-model="localEditionDataForm.author.value">
                </v-text-field>
              </v-card>
            </v-col>
            <v-col class="px-0 pb-0" v-if="blogListe && siteInfoListe && siteInfoListe.length > 1">
              <v-card v-if="!isPosted" class="mb-3 px-4 py-2 card" variant="flat">
                <v-select :variant="!isPosted ? 'underlined' : 'text'" v-model="localSelectedSite"
                  :items="siteInfoListe" item-title="label" label="Site sélectionné" hide-details return-object>
                  <template v-slot:selection="{ item }">
                    <div align="left">
                      {{ item.title }}
                    </div>
                  </template>
                </v-select>
              </v-card>
              <v-card v-else class="pa-2 mb-3 card" variant="flat" align="left">
                Site sélectionné : <span style="font-weight: bold;">{{ localSelectedSite.label
                  }}</span>
              </v-card>
              <div
                v-if="blogListe && localSelectedSite && localSelectedSite.label && blogListe[localSelectedSite.label]">
                <div v-if="!isPosted">
                  <v-card v-if="blogListe[localSelectedSite.label] && blogListe[localSelectedSite.label].length > 0"
                    class="px-4 py-2 card" variant="flat">
                    <v-select
                      :style="{ border: localEditionDataForm.post_compte_id.isValide == false ? 'solid 2px red' : '' }"
                      v-model="localEditionDataForm['post_compte_id'].value" variant="underlined" clearable hide-details
                      :label="localEditionDataForm['post_compte_id'].label" item-value="id"
                      :items="blogListe[localSelectedSite.label]">
                      <template v-slot:selection="{ item }">
                        <div align="left">
                          {{ item.title }}
                        </div>
                      </template>
                    </v-select>
                  </v-card>
                </div>
                <div v-else>
                  <v-card
                    v-if="blogListe && blogListe.length > 0 && localSelectedSite && blogListe[localSelectedSite.label] && blogListe[localSelectedSite.label][0]"
                    class="pa-2 card" variant="flat" align="left">
                    <div>
                      Blog de publication : <span style="font-weight: bold;">{{
                        blogListe[localSelectedSite.label].find((val) =>
                          val.id ==
                          localEditionDataForm['post_compte_id'].value.id).title }}</span>
                    </div>
                  </v-card>
                </div>
              </div>
            </v-col>
            <v-col class="px-0 pb-0" v-if="getHtmlLinks && Object.values(getHtmlLinks).length > 0">
              <v-card class="px-4 py-2 card" variant="flat">
                <div align="left" style="color: grey; font-size: 12px;">
                  Liste des liens utilisé
                </div>
                <v-col class="px-3">
                  <v-row>
                    <v-col v-for="(links, idx) in getHtmlLinks" :key="idx" align="left" cols="auto" class="py-0 px-0">
                      <v-tooltip activator="parent" location="start"> {{ links.url }}</v-tooltip>
                      <a :href="links.url" style="font-size: 14px;" target="_blank" class="pr-1">
                        {{ links.text
                        }}</a>
                    </v-col>
                  </v-row>
                </v-col>
              </v-card>
            </v-col>
            <v-col class="px-0  pb-0" v-if="localEditionDataForm['tags'] && localEditionDataForm['tags'].value">
              <v-card class=" card" variant="flat">
                <div class="py-2 px-1">
                  <v-combobox label="Balise (tags) SEO" variant="underlined" closable-chips multiple chips
                    v-model="localSelectedTags" hide-details style="border: none;border-radius: 5px;" class="pa-2"
                    :items="localEditionDataForm['tags'].value.split(',')">
                  </v-combobox>
                </div>
              </v-card>
            </v-col>
          </div>
          <v-col align="left" class="px-0 pt-1 pb-0" cols="12"
            v-if="publishNow == false && localSelectedSite && localSelectedSite.compte_token">
            <v-card class="px-1 pb-3 pt-3  card" style="text-transform: none;width: 100%;" variant="flat"
              :style="{ border: localEditionDataForm.publication_date.isValide == false ? 'solid 2px red' : '' }">
              <div class="pl-3" style="font-size: 12px;color:grey !important">
                Date de publication
              </div>
              <div class="px-2">
                <input style="width:100%; border: solid 1px lightgrey;border-radius: 5px;" class="pa-2 mt-1 mb-1"
                  type="datetime-local" v-model="localEditionDataForm.publication_datetime.value" :disabled="isPosted">
              </div>
            </v-card>
          </v-col>
        </div>
      </v-col>
      <v-col md="9" cols="12" class="px-2 pb-0 " style="position: relative;background-color: white">
        <v-card v-if="!isMobile"
          style="position: absolute; bottom: 5px;right: 5px; z-index: 2;border: solid 3px var(--C1);" variant="flat"
          class="pa-2">
          <div style="font-weight: bold;">
            Export
          </div>
          <v-btn class="button ma-1" @click="creatHtml" color="blue"><v-icon size="30">mdi-file-code</v-icon></v-btn>
          <v-btn class="button ma-1" @click="downloadAsWord" color="blue">
            <v-icon size="30">mdi-file-word</v-icon></v-btn>
        </v-card>
        <v-card v-if="!isPosted" class="py-2 px-4" variant="flat"
          style="border: solid 1px lightgray; border-radius: 10px;">
          <v-text-field :style="{ border: localEditionDataForm.title.isValide == false ? 'solid 2px red' : '' }"
            :label="localEditionDataForm['title'].label" hide-details v-model="localEditionDataForm['title'].value"
            variant="underlined">
          </v-text-field>
        </v-card>
        <v-card v-else variant="text" align="left" class="py-3">
          Titre de l'article : <span style="font-weight: bold;">{{ localEditionDataForm['title'].value
            }}</span>
        </v-card>
        <div style="background-color: white;position:relative" class=""
          :style="{ border: localEditionDataForm.description.isValide == false ? 'solid 2px red' : 'none' }">
          <div v-if="isPosted">
            <h4 align="left" style="color:#F44336;" class="pa-2">Cet article est publié est n'est plus
              modifiable.</h4>
            <v-textarea class="mt-2" bg-color="white"
              v-if="contentType.raw.includes(localEditionDataForm.platform.value)" v-model="content" readonly auto-grow
              hide-details></v-textarea>

            <div v-if="contentType.html.includes(localEditionDataForm.platform.value)" class="" align="left">
              <editor-content style="" :editor="editor" />
            </div>

          </div>
          <div v-else>
            <div>
              <div class="container">
                <div v-if="editor && contentType.html.includes(localEditionDataForm.platform.value)"
                  style="position: sticky; top:0px;z-index:1" class="mt-4">
                  <v-col cols="12" class=""
                    style="border: solid 1px var(--C1); border-bottom: none; border-right: none; ">
                    <v-row>
                      <v-col v-for="(item, i) in btnListe" :key="i" cols="" class="pa-0"
                        style="border-right: solid 1px var(--C1);border-bottom: solid 1px var(--C1);">
                        <v-menu v-if="item.isMenu" transition="slide-y-transition" variant="flat">
                          <template v-slot:activator="{ props }">
                            <v-btn v-bind="props" variant="flat" append-icon="mdi-menu-down"
                              style="border-radius: 0px;background-color: white;color: var(--C1);width: 100%;">
                              <v-icon :size="item.iconSize">{{ item.icon }} </v-icon>
                            </v-btn>
                          </template>
                          <v-btn v-for="(btn, idx) in item.values" :key="idx" variant="flat" style="border-radius: 0px;"
                            @click="runFunction(item.function, btn.value)" :color="btn.color">
                            <v-icon v-if="btn.icon" size="30"> {{ btn.icon }}</v-icon>
                            {{
                              btn.label
                            }}</v-btn>
                        </v-menu>
                        <v-btn v-else variant="flat"
                          style="border-radius: 0px;background-color: white;color: var(--C1);width:100%"
                          @click="runFunction(item.function, item.value)"
                          :disabled="item.canBeDisabled ? !editor.can().chain().focus()[item.function.name]().run() : false">
                          <v-icon :size="item.iconSize">{{ item.icon }} </v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="" class="pa-0"
                        style="border-right: solid 1px var(--C1);border-bottom: solid 1px var(--C1);">
                        <v-btn @click="isImageModalOpen = true" variant="flat"
                          style="border-radius: 0px;background-color: white;color: var(--C1);width:100%">
                          <v-icon :size="30"> mdi-image-plus </v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="" class="pa-0"
                        style="border-bottom: solid 1px var(--C1);border-right: solid 1px var(--C1)">
                        <v-btn @click="toggleHtml" variant="flat"
                          style="border-radius: 0px;background-color: white;color: var(--C1);width:100%">
                          <v-icon :size="30"> mdi-file-code-outline </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </div>
                <v-col class="py-3 pb-0 px-0" align=right>
                  <v-chip class="" style="background: var(--BG); color: white;font-weight: bold;">
                    Nombre de mot(s) <v-icon>mdi-approximately-equal</v-icon>{{ compterMots }}
                  </v-chip>
                  <v-chip class=" ml-2" style="background: var(--BG); color: white;font-weight: bold;">
                    Nombre de caractère(s) <v-icon>mdi-approximately-equal</v-icon>{{ compterCaracteres }}
                  </v-chip>
                  <v-chip v-if="localEditionDataForm.platform.value == 'Instagram'" class=" ml-2"
                    style="background: #EF5350; color: white;font-weight: bold;">
                    Instagram max caractères <v-icon class="mx-1" size="15">mdi-less-than-or-equal</v-icon> 2100
                  </v-chip>
                </v-col>
                <v-col class="px-0 pb-0 contentClass">
                  <v-card v-if="contentType.html.includes(localEditionDataForm.platform.value)" class=""
                    :style="showHtml ? 'background-color:#263238;color:white' : ''">
                    <div v-if="showHtml">
                      <v-textarea v-model="content" readonly auto-grow rows="1" hide-details></v-textarea>
                    </div>
                    <div v-else class="" align="left">
                      <editor-content style="" :editor="editor" />
                    </div>
                  </v-card>
                  <v-card v-if="contentType.raw.includes(localEditionDataForm.platform.value)">
                    <v-textarea variant="flat" style="border: solid 1px var(--C1);" class="mt-2" bg-color="white"
                      v-model="content" auto-grow hide-details></v-textarea>
                  </v-card>
                </v-col>
              </div>
            </div>
          </div>
        </div>
        <!-- </div> -->
        <iframe v-if="pdfDataUrl" :src="pdfDataUrl" width="100%" height="500px"></iframe>
      </v-col>
      <v-col cols="12" v-if="!isPosted" style="background-color: white;">
        <v-btn v-if="!showHtml" class="button" variant="flat" style="width: 100%;" @click="formVerification()">
          {{ validationLabel }}
        </v-btn>
        <v-btn v-else class="button" variant="flat" disabled style="width: 100%;">
          Sauvegarde indisponible en mode HTML
        </v-btn>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>

// import { VueEditor } from "vue3-editor";

import { ref, onMounted } from 'vue'
import moment from 'moment'
// import html2canvas from 'html2canvas'
import htmlDocx from 'html-docx-js/dist/html-docx'
import { Color } from '@tiptap/extension-color'
import ListItem from '@tiptap/extension-list-item'
import TextStyle from '@tiptap/extension-text-style'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import { Editor, EditorContent } from '@tiptap/vue-3'

import CustomInlineStyle from '../editorExtension/CustomInlineStyle';
import CustomImage from '../editorExtension/customImg';

import {
  v4 as uuidv4
} from 'uuid';

// import SummernoteEditor from 'vue3-summernote-editor';

export default {
  props: {
    getOperatingSystem: {},
    localDataForm: { required: true },
    groupInformation: { required: true },
    validationFunction: { type: Function },
    validationLabel: {},
    blogListe: { required: true },
    isPosted: {},
    setIsLoading: { type: Function },
    closeModal: { type: Function },
    selectedSite: { required: true },
    siteInfoListe: {},
    selectedTags: {}
  },

  setup(props) {
    const error = ref({ isShowing: false, errorLabel: '' })
    const localSelectedSite = ref(undefined)
    const localEditionDataForm = ref(undefined)
    const contentType = ref({
      'html': ['Shopify', 'shopify'],
      'raw': ['Instagram', 'instagram']
    })
    const isLoading = ref(false)
    const publishNow = ref(false)
    const maxImageWidth = ref(undefined)
    const contentStyleData = ref(undefined)
    const dialogIsOpen = ref({})
    const localSelectedTags = ref({})

    const newImageData = ref({})
    const isImageModalOpen = ref(false)
    const showHtml = ref(false)
    const content = ref('')
    const editor = ref(null)
    const btnListe = ref([
      {
        isMenu: false,
        function: {
          type: 'complex',
          name: 'toggleBold'
        },
        icon: 'mdi-format-bold',
        iconSize: '30',
      },
      {
        isMenu: true,
        function: {
          type: 'complex',
          name: 'toggleHeading'
        },
        icon: 'mdi-format-header-pound',
        iconSize: '30',
        values: [
          { value: { level: 1 }, label: 'H1' },
          { value: { level: 2 }, label: 'H2' },
          { value: { level: 3 }, label: 'H3' },
          { value: { level: 4 }, label: 'H4' }
        ]
      },
      {
        isMenu: false,
        function: {
          type: 'complex',
          name: 'toggleItalic'
        },
        icon: 'mdi-format-italic',
        iconSize: '30',
      },
      {
        isMenu: false,
        function: {
          type: 'complex',
          name: 'toggleBulletList'
        },
        icon: 'mdi-format-list-bulleted',
        iconSize: '30',
      },
      {
        isMenu: false,
        function: {
          type: 'complex',
          name: 'toggleStrike'
        },
        icon: 'mdi-format-strikethrough',
        iconSize: '30',
      },
      {
        isMenu: false,
        function: {
          type: 'complex',
          name: 'toggleOrderedList'
        },
        icon: 'mdi-order-numeric-ascending',
        iconSize: '30',
      },
      {
        isMenu: true,
        function: {
          type: 'complex',
          name: 'setColor'
        },
        icon: 'mdi-palette',
        iconSize: '30',
        values: [
          { value: 'black', color: 'black' },
          { value: 'white', color: 'white' },
          { value: 'red', color: 'red' },
          { value: 'purple', color: 'purple' },
        ]
      },
      {
        isMenu: false,
        function: {
          type: 'complex',
          name: 'toggleUnderline'
        },
        icon: 'mdi-format-underline',
        iconSize: '30',
      },
      {
        isMenu: true,
        function: {
          type: 'complex',
          name: 'setTextAlign'
        },
        icon: 'mdi-format-align-left',
        iconSize: '30',
        values: [
          { value: 'left', icon: 'mdi-format-align-left' },
          { value: 'center', icon: 'mdi-format-align-center' },
          { value: 'right', icon: 'mdi-format-align-right' },
          { value: 'justify', icon: 'mdi-format-align-justify' }
        ]
      },
      {
        isMenu: false,
        function: {
          type: 'complex',
          name: 'undo'
        },
        icon: 'mdi-undo',
        iconSize: '30',
        canBeDisabled: true,
      },
      {
        isMenu: false,
        function: {
          type: 'complex',
          name: 'redo'
        },
        icon: 'mdi-redo',
        iconSize: '30',
        canBeDisabled: true,
      },
    ])


    const setHtmlEnvInContent = function () {

      content.value = `
      <html>
        <head>
          <meta charset='UTF-8'>
        </head>
        <body>
          <style>
            ${contentStyleData.value ? contentStyleData.value : ''}
          </style>
          <div class='contentClass'>
            ${content.value}
          </div>
        </body>
      </html>`

      // .contentClass img {
      //         border: solid 10px red !important;
      //         float:none; 
      //       } 

    }

    onMounted(() => {
      localEditionDataForm.value = JSON.parse(JSON.stringify(props.localDataForm))
      localSelectedSite.value = JSON.parse(JSON.stringify(props.selectedSite))
      localSelectedTags.value = JSON.parse(JSON.stringify(props.selectedTags))

      content.value = localEditionDataForm.value.description.value
      setHtmlEnvInContent()

      if (localEditionDataForm.value.metadata.value && localEditionDataForm.value.metadata.value.length > 0)
        localEditionDataForm.value.metadata.value = JSON.parse(localEditionDataForm.value.metadata.value)
      else
        localEditionDataForm.value.metadata.value = {}

      if (localEditionDataForm.value.links_redirect.value && localEditionDataForm.value.links_redirect.value.length > 0)
        localEditionDataForm.value.links_redirect.value = JSON.parse(localEditionDataForm.value.links_redirect.value)
      else
        localEditionDataForm.value.links_redirect.value = {}

      if (props.blogListe && props.blogListe[localSelectedSite.value.label]) {
        localEditionDataForm.value['post_compte_id'].value = props.blogListe[localSelectedSite.value.label].find((val) => val.id == localEditionDataForm.value['post_compte_id'].value)
      }

      editor.value = new Editor({
        extensions: [
          Color.configure({ types: [TextStyle.name, ListItem.name] }),
          TextStyle.configure({ types: [ListItem.name] }),
          StarterKit,
          Underline,
          TextAlign.configure({
            types: ['heading', 'paragraph'],
            defaultAlignment: 'left', // Alignement par défaut
          }),
          Image,
          CustomInlineStyle,
          CustomImage,
          Link.configure({
            openOnClick: true,
            linkOnPaste: true,
          }),
        ],
        onUpdate({ editor }) {
          content.value = editor.getHTML();
          listenImageClick()
        },
        content: content.value,
        editable: !props.isPosted
      })
      editor.value.commands.setContent(content.value);

      listenImageClick()
    })

    const creatHtml = function () {
      var texte = content.value;

      // Création d'un objet Blob avec le texte
      var blob = new Blob([texte], { type: "text/plain;charset=utf-8" });

      // Création d'un objet URL à partir du Blob
      var url = URL.createObjectURL(blob);

      // Création d'un élément <a> pour télécharger le fichier
      var a = document.createElement("a");
      a.href = url;
      a.download = "article.html"; // Nom du fichier à télécharger
      document.body.appendChild(a);
      a.click();
    }

    const getBase64Image = function (file, callback) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        callback(reader.result);
      };
    }


    const setNewPhoto = function (file) {
      getBase64Image(file[0], function (dateUrl) {
        localEditionDataForm.value.image_path.value = dateUrl
      })
    }

    const formVerification = function () {
      props.setIsLoading(true)
      let keyNotValid = []
      for (const obj of Object.values(localEditionDataForm.value)) {
        if (obj.isRequired == true && (obj.value == undefined || obj.value.length == 0)) {
          obj.isValide = false
          keyNotValid.push(obj.label)
        } else {
          obj.isValide = true
        }
      }
      if (keyNotValid.length == 0) {
        savePip()
      } else {
        props.setIsLoading(false)
        this.error.errorLabel = 'ERREUR : '
        for (let i = 0; i < keyNotValid.length; i++) {
          this.error.errorLabel += keyNotValid[i] + ' / '
        }
        this.error.isShowing = true
      }
    }

    const savePip = function () {
      localEditionDataForm.value.tags.value = localSelectedTags.value.join(',')
      localEditionDataForm.value.metadata.value = JSON.stringify(localEditionDataForm.value.metadata.value)
      localEditionDataForm.value.description.value = content.value
      props.validationFunction(localEditionDataForm.value)
    }

    const listenImageClick = function () {
      console.log("listenImageClick");

      let images = editor.value.view.dom.querySelectorAll('img')
      images.forEach(image => {

        let newWidth = image.style.maxWidth.split('px')

        if (newWidth.length == 2) {
          let width = parseInt(newWidth[0])
          if (maxImageWidth.value == undefined)
            maxImageWidth.value = width
          else
            if (maxImageWidth.value < width)
              maxImageWidth.value = width
        }

        image.id = uuidv4()
        image.addEventListener('click', (event) => {
          let clickedImage = event.target;
          handleImageClick(clickedImage);
        })
      })

    }

    const handleImageClick = function (image) {
      let styleFormated = {}
      let styleFormatedTmp = image.style.cssText.split(';')

      for (let i = 0; i < styleFormatedTmp.length; i++) {
        let cssValue = styleFormatedTmp[i].split(':')
        if (cssValue.length == 2 && cssValue[0].length > 0)
          styleFormated[cssValue[0].trim()] = cssValue[1].trim()
      }

      newImageData.value = {
        src: image.src,
        id: image.id,
        alt: image.alt,
        maxWidth: styleFormated['max-width'] != undefined ? styleFormated['max-width'].replace('px', '') : undefined,
        height: styleFormated['height'] != undefined ? styleFormated['height'].replace('px', '') : undefined,
      }
      isImageModalOpen.value = true
    }

    const addImage = function () {
      let style = `padding:1vw;width:100%;@@HEIGHT@@@@WIDTH@@@@POS@@`

      if (newImageData.value.height) style = style.replace('@@HEIGHT@@', 'height:' + newImageData.value.height + 'px;')
      else style = style.replace('@@HEIGHT@@', '')

      if (newImageData.value.maxWidth) style = style.replace('@@WIDTH@@', 'max-width:' + newImageData.value.maxWidth + 'px;')
      else style = style.replace('@@WIDTH@@', '')

      if (newImageData.value.pos) style = style.replace('@@POS@@', newImageData.value.pos + ';')
      else style = style.replace('@@POS@@', '')

      if (newImageData.value.id == undefined) {
        let img = `<img src='${newImageData.value.src}' alt='${newImageData.value.alt}' style='${style}' />`

        editor.value
          .chain()
          .focus()
          .insertContent(img)
          .run()
        listenImageClick()
      } else {
        let styleFormated = {}
        let styleFormatedTmp = style.split(';')

        for (let i = 0; i < styleFormatedTmp.length; i++) {
          let cssValue = styleFormatedTmp[i].split(':')
          if (cssValue.length == 2 && cssValue[0].length > 0)
            styleFormated[cssValue[0].trim()] = cssValue[1].trim()
        }

        console.log("findImage ID :", newImageData.value.id);

        findImage(newImageData.value.id, function (findedImage) {
          if (styleFormated['max-width'] == undefined) findedImage.style.removeProperty('max-width');
          else findedImage.style.maxWidth = styleFormated['max-width'];

          if (styleFormated['height'] == undefined) findedImage.style.removeProperty('height');
          else findedImage.style.height = styleFormated['height'];

          if (styleFormated['float'] == undefined) findedImage.style.removeProperty('float');
          else findedImage.style.float = styleFormated['float'];

          if (styleFormated['display'] == undefined) findedImage.style.removeProperty('display');
          else findedImage.style.display = styleFormated['display'];


          if (styleFormated['margin'] == undefined) findedImage.style.removeProperty('margin');
          else findedImage.style.margin = styleFormated['margin'];

          findedImage.alt = newImageData.value.alt;
          findedImage.src = newImageData.value.src;

          let serializer = new XMLSerializer();
          let localEditor = editor.value.view.dom.cloneNode(true);
          localEditor.querySelectorAll('br').forEach(br => br.remove())
          content.value = serializer.serializeToString(localEditor);
          editor.value.commands.setContent(content.value);

          listenImageClick()
        })

      }
      isImageModalOpen.value = false
      newImageData.value = {}
    }

    const closeImageModal = function () {
      isImageModalOpen.value = false
      newImageData.value = {}
    }

    const findImage = function (id, callback) {
      let images = editor.value.view.dom.querySelectorAll('img')
      for (let image of images) {
        if (image.id == id) {
          callback(image)
          break;
        }
      }
    }

    const toggleHtml = function () {
      showHtml.value = !showHtml.value;
      if (showHtml.value) {
        content.value = editor.value.getHTML();
      } else {
        editor.value.commands.setContent(content.value);
      }
    }

    const runFunction = function (functionData, param) {
      if (functionData.type == 'basic') {
        [functionData.name](param)
      } else if (functionData.type == 'complex') {
        if (param) {
          editor.value.chain().focus()[functionData.name](param).run()
        } else
          editor.value.chain().focus()[functionData.name]().run()
      }
    }

    return {
      isLoading,
      localEditionDataForm,
      setNewPhoto,
      dialogIsOpen,
      publishNow,
      formVerification,
      error,
      localSelectedSite,
      localSelectedTags,
      creatHtml,
      contentType,
      content,
      editor,
      btnListe,
      showHtml,
      runFunction,
      addImage,
      closeImageModal,
      toggleHtml,
      listenImageClick,
      isImageModalOpen,
      newImageData,
      maxImageWidth,
      setHtmlEnvInContent,
      contentStyleData
    }
  },
  data() {
    return {
      pdfDataUrl: '',
      isShowHtmlOpen: false
    }
  },
  watch: {
    maxImageWidth() {
      this.updateContentStyle()
      this.setHtmlEnvInContent()
    },
    blogListe(val) {
      if (val && val[this.localSelectedSite.label]) {
        this.localEditionDataForm['post_compte_id'].value = val[this.localSelectedSite.label].find((val) => val.id == this.localEditionDataForm['post_compte_id'].value)
      }
    },
    showHtml(val) {
      this.listenImageClick()
      if (val == false) {
        this.setHtmlEnvInContent()
      }
    },
    content(newHtml) {
      if (this.showHtml && this.editor) {
        this.editor.commands.setContent(newHtml);
      }
    }

  },
  mounted() {
    // $(this.$refs.summernote).summernote();
    moment.locale('fr')
    this.updateContentStyle()
  },
  computed: {
    compterMots() {
      let element = document.createElement('div');
      element.innerHTML = this.content;

      let texteBrut = element.textContent || element.innerText || "";

      let chaine = texteBrut.trim();

      chaine = chaine.replace(/[-']/g, " ");
      chaine = chaine.replace(/\s+/g, " ");

      if (chaine === "") {
        return 0;
      }

      let mots = chaine.split(/\s+/);
      return mots.length;
    },
    compterCaracteres() {
      let element = document.createElement('div');
      element.innerHTML = this.content;

      let texteBrut = element.textContent || element.innerText || "";

      let chaine = texteBrut.trim();
      let chaineSansEspacesNiRetours = chaine.replace(/[\s]/g, "");

      return chaineSansEspacesNiRetours.length;
    },
    isMobileXs() {
      return this.$vuetify.display.xs
    },
    isMobile() {
      return this.$vuetify.display.mobile
    },
    getHtmlLinks() {
      if (this.localEditionDataForm && this.content) {
        return Array.from(new DOMParser().parseFromString(this.content,
          'text/html').querySelectorAll('a')).map(link => ({
            'url':
              link.href, 'text': link.innerHTML
          }))
      } else
        return undefined
    }
  },
  methods: {
    updateContentStyle() {
      if (this.maxImageWidth) {
        var style = document.createElement('style');
        this.contentStyleData = `
          .contentClass img {
            padding:1vw
          }

          @media only screen and (max-width: ${this.maxImageWidth + 400}px) {
            .contentClass img {
              float:none !important;
            }
          }
        `;

        style.innerHTML = this.contentStyleData
        document.head.appendChild(style);
      }
    },
    downloadAsWord() {
      let content = `
      <html>
        <head>
          <meta charset="UTF-8">
        </head>
        <body>
          ${this.content}
        </body>
      </html>
    `
      let blob = htmlDocx.asBlob(content)
      let url = URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = 'article.docx'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      URL.revokeObjectURL(url)
    },
    chooseImage() {
      this.$refs.file_upload.click()
    },
    formateDate(date, format) {
      return moment(date).format(format)
    },
  },
  components: {
    EditorContent
  },
  // beforeUnmount() {
  //   this.editor.destroy()
  // },

}

</script>

<style lang="scss">
.tiptap.ProseMirror {
  padding: 16px;
  border: solid 1px var(--C1);
}

.tiptap {
  :first-child {
    margin-top: 0;
  }

  ul,
  ol {
    padding: 0 1rem;
    margin: 1.25rem 1rem 1.25rem 0.4rem;

    li {
      margin-left: 10px !important;
    }

    li p {
      margin-top: 0.25em;
      margin-bottom: 0.25em;
    }
  }
}
</style>
