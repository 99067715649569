<template>
    <div v-if="code == undefined">
        <v-col style="position: absolute;font-size: 20px;font-weight: bold;bottom: 0px;">
            Se connecter pour suivre le compte rendu de votre google analytics/search.
        </v-col>
        <v-row style="height: 90vh; " justify="center" align=center>
            <v-col cols="12" align="center">
                <v-col style="font-weight: bold; font-size: 30px;  padding-bottom: 40px;">
                    Jauris SEO
                </v-col>
                <v-btn @click="googleLog" width="420" height="80" style="border-radius: 40px;">
                    <img class="mr-3" src="./google-icon.webp" height="50" width="50" />
                    <span style="font-size: 15px;font-weight: bold;">
                        Se connecter avec Google
                    </span>
                </v-btn>
            </v-col>
        </v-row>
    </div>
    <div v-else>
        <v-col v-if="Object.keys(analyticsDataListe).length > 0" class="pa-3">
            <v-card v-for="(pivotData, idxPivot) in getPivotDataSorted"
                :key="idxPivot" class="pa-6 mb-4" variant="flat">
                <v-row>
                    <v-col v-if="false">
                        <v-row>
                            <v-col class="pa-1">
                                <v-select :items="dateRange" v-model="pivotData.requestBody.requests[0].dateRanges[0]"
                                    hide-details>
                                </v-select>
                            </v-col>
                            <v-col class="pa-1">
                                <v-select
                                    @update:modelValue="function () { pivotData.requestBody.requests[0].metrics = [] }"
                                    v-model="pivotData.requestBody.requests[0].dimensions[0].name" :items="dimensions"
                                    item-title="title" hide-details>
                                </v-select>
                            </v-col>
                            <v-col cols="12" class="pa-1">
                                <v-select v-model="pivotData.requestBody.requests[0].metrics" :items="getMetricsFromName(dimensions.find((val) => val.value ==
                                    pivotData.requestBody.requests[0].dimensions[0].name).metrics)" multiple
                                    item-title="name" return-object hide-details>
                                    <template v-slot:selection="{ item }">
                                        <v-chip class="my-1">
                                            <span>{{ descriptionByKey[item.title] }}</span>
                                        </v-chip>
                                    </template>
                                    <template v-slot:item="{ props, item }">
                                        <div v-bind="props">
                                            <v-list-item style="cursor: pointer;"
                                                :style="{ 'background-color': pivotData.requestBody.requests[0].metrics.map((val) => val.name).includes(item.title) ? 'lightgrey' : 'white' }">
                                                {{ descriptionByKey[item.title] }}
                                            </v-list-item>
                                        </div>
                                    </template>
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" class="pa-2" v-if="pivotData && pivotData.requestBody">
                        <div style="font-weight: bold;font-size: 15px;" class="py-1">

                            Afficher les données par
                            « <span style="color: red;"> {{
                                descriptionByKey[pivotData.data[0].dimensionHeaders[0].name] }}</span>
                            »
                            sur l'interval des
                            « <span style="color: red;"> {{
                                descriptionByKey[pivotData.requestBody.requests[0].dateRanges[0].startDate] }}
                            </span> »
                            <br> concernant les données suivante : <span style="color: red;"> {{
                                pivotData.requestBody.requests[0].metrics.map((val) => descriptionByKey[val.name])
                                    .join(' / ') }} </span>
                        </div>
                    </v-col>
                    <v-col v-for="(pivot, idx) in pivotData.data" :key="idx" class="seo-table pa-1">
                        <v-card v-if="false">
                            <Radar height="500px"
                                :data="getChartData(pivot.rows.map((val) => val.dimensionValues[0].value), pivot.rows.map((val) => val.metricValues).map((val2) => val2.map((val3) => val3.value)))"
                                :options="chartOptions" />

                            <Bar height="500px"
                                :data="getChartData(pivot.rows.map((val) => val.dimensionValues[0].value), pivot.rows.map((val) => val.metricValues).map((val2) => val2.map((val3) => val3.value)))"
                                :options="chartOptions" />
                        </v-card>
                        <v-card style="border: solid 2px var(--C1);" variant="flat">
                            <table>
                                <tr style="font-weight: 100;">
                                    <th v-for="dimension in pivot.dimensionHeaders" :key="dimension.name">
                                        {{ descriptionByKey[dimension.name] }}
                                    </th>
                                    <th v-for="(metric, metrixIdx)  in pivot.metricHeaders" :key="metrixIdx">
                                        {{ descriptionByKey[metric.name] }}
                                    </th>
                                </tr>
                                <tr v-for="(rows, rowsIdx) in pivot.rows" :key="rowsIdx">
                                    <th>
                                        {{ rows.dimensionValues[0].value }}
                                    </th>
                                    <td v-for="(metricValue, metrixIdx) in pivot.rows[rowsIdx].metricValues"
                                        :key="metrixIdx">
                                        {{ metricValue.value }}
                                    </td>
                                </tr>
                            </table>
                        </v-card>
                    </v-col>
                    <!-- <v-col cols="12" class="pa-1  my-2" align=center>
                        <v-btn variant="flat" class="button">
                            Valider mes modifications
                        </v-btn>
                    </v-col> -->

                </v-row>
            </v-card>
        </v-col>

    </div>
</template>

<script>

import { Bar } from 'vue-chartjs'
// import { PolarArea } from 'vue-chartjs'
import { Radar } from 'vue-chartjs'

import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, RadialLinearScale, ArcElement, PointElement, LineElement, Filler } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, RadialLinearScale, ArcElement, PointElement, LineElement, Filler)

// import engine from "@/core/index"
export default {
    data() {
        return {
            chartOptions: {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: false,
                        text: 'Chart.js Bar Chart'
                    }
                }
            },
            code: undefined,
            propertyId: 'properties/443633281',
            clientId: '833319283097-osoaicbmjjt8a8qplv8tb249s8cuk3nn.apps.googleusercontent.com',
            analyticsDataListe: [],
            basicChartData: { position: 0, title: '', dateRanges: [], metrics: [], dimensions: [], pivots: [] },
            dateRange: [
                { title: '7 derniers jours', value: { "startDate": "7daysAgo", "endDate": "today" } },
                { title: '15 derniers jours', value: { "startDate": "15daysAgo", "endDate": "today" } },
                { title: '30 derniers jours', value: { "startDate": "30daysAgo", "endDate": "today" } }
            ],
            descriptionByKey: {
                "eventCount": "Nombre d'événements",
                "activeUsers": "Utilisateurs actifs",
                "screenPageViews": "Vues",
                "sessions": "Sessions",
                "scrolledUsers": "Utilisateurs ayant fait défiler la page",
                "totalUsers": "Nombre total d'utilisateurs",
                "organicGoogleSearchClickThroughRate": "Taux de clics dans la recherche naturelle Google",
                "organicGoogleSearchAveragePosition": "Position moyenne dans la recherche naturelle Google",
                "organicGoogleSearchClicks": "Clics dans la recherche naturelle Google",
                "organicGoogleSearchImpressions": "Impressions dans la recherche naturelle Google",
                "unifiedScreenName": "Titre de la page et nom de l'écran",
                "region": "Région",
                "pageTitle": "Titre de page",
                "month": "Mois",
                "deviceCategory": "Catégorie de plate-forme et d'appareil",
                "dayOfWeekName": "Nom du jour de la semaine",
                "pagePath": "Chemin de page",
                "country": "Pays",
                "city": "Ville",
                "30daysAgo": "30 derniers jours",
                "15daysAgo": "15 derniers jours",
                "7daysAgo": "7 derniers jours",
            },
            metricsListe: {
                metricsV1: [
                    { "name": "eventCount" },
                    { "name": "activeUsers" },
                    { "name": "screenPageViews" },
                    { "name": "sessions" },
                    { "name": "scrolledUsers" },
                    { "name": "totalUsers" },
                ],
                metricsV2: [
                    { "name": "organicGoogleSearchClickThroughRate" },
                    { "name": "organicGoogleSearchAveragePosition" },
                    { "name": "organicGoogleSearchClicks" },
                    { "name": "organicGoogleSearchImpressions" },
                ],
            },
            dimensions: [
                { value: 'unifiedScreenName', title: 'Titre de la page et nom de l\'écran', metrics: ['metricsV1'] },
                { value: 'region', title: 'Région', metrics: ['metricsV1'] },
                { value: 'pageTitle', title: 'Titre de page', metrics: ['metricsV1'] },
                { value: 'month', title: 'Mois', metrics: ['metricsV1', 'metricsV2'] },
                { value: 'deviceCategory', title: 'Catégorie de plate-forme et d\'appareil', metrics: ['metricsV1', 'metricsV2'] },
                { value: 'dayOfWeekName', title: 'Nom du jour de la semaine', metrics: ['metricsV1', 'metricsV2'] },
                { value: 'pagePath', title: 'Chemin de page', metrics: ['metricsV1'] },
                { value: 'country', title: 'Pays', metrics: ['metricsV1', 'metricsV2'] },
                { value: 'city', title: 'Ville', metrics: ['metricsV1'] },
                { value: 'defaultChannelGroup', title: 'canaux', metrics: ['metricsV1'] },
            ],
        }
    },
    computed: {
        getPivotDataSorted(){
            let sortedData = JSON.parse(JSON.stringify(this.analyticsDataListe)).sort((a, b) => a.position - b.position)
            return sortedData
        },
        dataToLoad() {
            return [
                {
                    position: 1,
                    dateRanges: [{ "startDate": "30daysAgo", "endDate": "today" }],
                    metrics: this.metricsListe.metricsV2,
                    dimensions: [{ "name": "defaultChannelGroup" }],
                    pivots: [{ "fieldNames": ["defaultChannelGroup"], "limit": 20 }]
                },
            ]
        }
    },
    methods: {
        getChartData(label, value) {
            let datasets = []

            for (let i = 0; i < label.length; i++) {
                datasets.push({
                    label: label[i], data: value[i], backgroundColor: ['red', 'green', 'purple', 'yellow', 'blue', 'orange', 'grey'],
                })
            }

            return {
                labels: label,
                datasets
            }
        },
        getMetricsFromName(metricsListe) {
            let finalMetrics = []

            for (let i = 0; i < metricsListe.length; i++) {
                let name = metricsListe[i]

                if (this.metricsListe[name])
                    finalMetrics = finalMetrics.concat(this.metricsListe[name])
            }

            return finalMetrics
        },
        googleLog() {
            let that = this

            window.google.accounts.oauth2.initTokenClient({
                client_id: that.clientId,
                scope: 'https://www.googleapis.com/auth/analytics.readonly',
                callback: (tokenResponse) => {
                    let accessToken = tokenResponse.access_token;
                    that.code = accessToken
                    localStorage.setItem('code', accessToken)
                    that.getData()
                }
            }).requestAccessToken();

        },
        getData() {
            let that = this
            let url = `https://analyticsdata.googleapis.com/v1beta/${this.propertyId}:batchRunPivotReports`;
            let headers = {
                'Authorization': 'Bearer ' + this.code,
                'Content-Type': 'application/json'
            }

            for (let i = 0; i < this.dataToLoad.length; i++) {
                let requestData = this.dataToLoad[i]

                let requestBody = {
                    "requests": [
                        {
                            "dateRanges": requestData.dateRanges,
                            "metrics": requestData.metrics,
                            "dimensions": requestData.dimensions,
                            "pivots": requestData.pivots
                        }
                    ]
                };

                fetch(url, { method: 'POST', headers, body: JSON.stringify(requestBody) })
                    .then(response => response.json())
                    .then(data => {
                        if (data.error) {
                            localStorage.setItem('code', undefined)
                            that.code = undefined
                        } else {
                            that.analyticsDataListe.push({ title: requestData.title, position: requestData.position, data: data.pivotReports, requestBody })
                        }
                    })
                    .catch(error => { console.error('Execute error', error); });
            }
        }
    },
    mounted() {
        if (localStorage.code) {
            this.code = localStorage.code
            this.getData()
        }
    },
    components: {
        Bar,
        Radar
    }
}
</script>

<style></style>